// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/loginbody.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@supports (-webkit-mask: none) and (not (cater-color: #666)){.login-container .el-input input{color:#666}}.app-pages{height:100%;display:-webkit-box;display:-ms-flexbox;display:flex}.app-slider{-webkit-box-flex:1;-ms-flex:1;flex:1}.slider-text{color:#fff;font-size:34px;position:absolute;bottom:80px;left:90px;font-weight:700;z-index:110}.slider-text p{margin:0;padding:0}.title{font-size:24px;color:#fff;margin:0px auto 30px auto;text-align:center;font-weight:bold;color:#0070C8}.login-top{color:#212025;text-align:center;position:relative;top:-65px}.login-top span{font-size:18px;font-weight:700;border-bottom:2px solid #0070C8}.carousels .el-carousel__button{width:10px;height:10px;border-radius:50%}.login-input .el-input__inner{border-radius:10px}.login-container{position:absolute;top:0;bottom:0;left:0;right:0;height:100%}.login-container .el-input{display:inline-block;height:47px;width:85%}.login-container .el-input input{background:transparent;border:0px;-webkit-appearance:none;border-radius:0px;padding:12px 5px 12px 15px;color:#000;height:47px;caret-color:#666}.login-container .el-input input:-webkit-autofill{-webkit-box-shadow:0 0 0px 1000px #fff inset !important;box-shadow:0 0 0px 1000px #fff inset !important;-webkit-text-fill-color:#666 !important}.login-container .el-form-item{border:none;background:transparent;border-radius:5px;color:#454545}.login-container:before{content:\"\";position:absolute;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center/cover no-repeat;width:100%;height:100%}\n", ""]);
// Exports
module.exports = exports;
