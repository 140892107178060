"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.menus = void 0;
// 总后台菜单权限专用
var menus = exports.menus = [{
  id: 1,
  path: '/menus',
  component: 'layout/Layout',
  redirect: '/',
  title: '菜品库',
  ishidden: false,
  // 是否隐藏
  ischildren: false,
  // 是否有子菜单
  children: [{
    path: '/menus',
    component: 'food/menus',
    name: 'menus',
    meta: {
      title: '菜品库',
      icon: 'menus',
      noCache: true,
      perms: []
    }
  }],
  perms: [{
    id: 20,
    title: '查询',
    value: 'dishes_list:GET'
  }, {
    id: 21,
    title: '新增',
    value: 'dishes_save:POST'
  }, {
    id: 22,
    title: '删除',
    value: 'dishes_del:GET'
  }, {
    id: 23,
    title: '编辑',
    value: 'dishes_edit:POST'
  }, {
    id: 110,
    title: '批量导出',
    value: 'dishes_report:GET'
  }],
  hidden: false // 默认不隐藏
}, {
  id: 2,
  path: '/editmenus',
  component: 'layout/Layout',
  redirect: '/',
  title: '菜品信息',
  ishidden: true,
  ischildren: false,
  children: [{
    path: '/editmenus',
    component: 'food/editmenus',
    name: 'editmenus',
    meta: {
      title: '菜品信息',
      icon: 'menus',
      noCache: true,
      perms: []
    }
  }],
  perms: [],
  hidden: true
}, {
  id: 3,
  path: '/material',
  component: 'layout/Layout',
  redirect: '/material',
  title: '食材库',
  ishidden: false,
  ischildren: false,
  children: [{
    path: '/material',
    component: 'food/material',
    name: 'material',
    meta: {
      title: '食材库',
      icon: 'shopping',
      noCache: true,
      perms: []
    }
  }],
  perms: [{
    id: 24,
    title: '查询',
    value: 'foodIngredients_list:GET'
  }, {
    id: 25,
    title: '新增',
    value: 'foodIngredients_save:POST'
  }, {
    id: 26,
    title: '删除',
    value: 'foodIngredients_del:GET'
  }, {
    id: 27,
    title: '编辑',
    value: 'foodIngredients_edit:POST'
  }, {
    id: 400,
    title: '批量导出',
    value: 'foodIngredients_export:POST'
  }, {
    id: 401,
    title: '批量导入',
    value: 'foodIngredients_upfile:POST'
  }],
  hidden: false
}, {
  id: 4,
  path: '/target',
  component: 'layout/Layout',
  redirect: '/target',
  title: '配餐对象',
  ishidden: false,
  ischildren: false,
  children: [{
    path: '/target',
    component: 'food/target',
    name: 'target',
    meta: {
      title: '配餐对象',
      icon: 'one',
      noCache: true,
      perms: []
    }
  }],
  perms: [{
    id: 28,
    title: '查询',
    value: 'cookbook_group_crowd_list:GET'
  }, {
    id: 29,
    title: '新增',
    value: 'cookbook_group_crowd_save:POST'
  }, {
    id: 30,
    title: '删除',
    value: 'cookbook_group_del:GET'
  }, {
    id: 31,
    title: '编辑',
    value: 'cookbook_group_crowd_edit:POST'
  }],
  hidden: false
},
// {
//   id: 5,
//   path: '/recipe',
//   component: 'layout/Layout',
//   redirect: '/recipe',
//   title: '本周食谱',
//   ishidden: false,
//   ischildren: false,
//   children: [
//     {
//       path: '/recipe',
//       component: 'food/recipe',
//       name: 'recipe',
//       meta: { title: '本周食谱', icon: 'form', noCache: true, perms: [] }
//     }
//   ],
//   perms: [{ id: 32, title: '查询', value: 'cookbook_weekly_currweek:GET' }, { id: 33, title: '新增', value: 'cookbook_weekly_base_save:GET' }, { id: 101, title: '编辑', value: 'cookboo_weekly_demo:POST' }],
//   hidden: false
// },
{
  id: 6,
  path: '/recipeedit',
  component: 'layout/Layout',
  redirect: '/recipeedit',
  title: '编辑食谱',
  ishidden: true,
  ischildren: false,
  children: [{
    path: '/recipeedit',
    component: 'food/recipeedit',
    name: 'recipeedit',
    meta: {
      title: '编辑食谱',
      icon: 'menus',
      noCache: true,
      perms: []
    }
  }],
  perms: [],
  hidden: true
}, {
  id: 7,
  path: '/myrecipe',
  component: 'layout/Layout',
  redirect: '/myrecipe',
  title: '我的食谱库',
  ishidden: false,
  ischildren: false,
  children: [{
    path: '/myrecipe',
    component: 'food/myrecipe',
    name: 'myrecipe',
    meta: {
      title: '我的食谱库',
      icon: 'documentation',
      noCache: true,
      perms: []
    }
  }],
  perms: [{
    id: 34,
    title: '查询',
    value: 'cookbook_weekly_list:GET'
  }, {
    id: 35,
    title: '新增',
    value: 'cookbook_weekly_base_save:POST'
  }, {
    id: 36,
    title: '删除',
    value: 'cookbook_weekly_del:POST'
  }, {
    id: 109,
    title: '导出',
    value: 'cookbook_weekly_report:GET'
  }, {
    id: 100,
    title: '导出(单条)',
    value: 'cookbook_weekly_detail:GET'
  }, {
    id: 102,
    title: '编辑食谱(名称)',
    value: 'cookbook_weekly_demo:GET'
  }, {
    id: 103,
    title: '编辑食谱(保存)',
    value: 'cookbook_weekly_demo:POST'
  }, {
    id: 104,
    title: '编辑食谱(添加菜品)',
    value: 'cookbook_add_menus:POST'
  }, {
    id: 105,
    title: '编辑食谱(删除菜品)',
    value: 'cookbook_del_menus:POST'
  }, {
    id: 106,
    title: '编辑食谱(添加食材)',
    value: 'cookbook_add_material:POST'
  }, {
    id: 107,
    title: '编辑食谱(删除食材)',
    value: 'cookbook_del_material:POST'
  }, {
    id: 108,
    title: '编辑食谱(修改用量)',
    value: 'cookbook_edit:POST'
  }, {
    id: 700,
    title: '上下架',
    value: 'cookbook_weekly_base_changeStatus:POST'
  }],
  hidden: false
}, {
  id: 8,
  path: '/food',
  component: 'layout/Layout',
  title: '查看我的食谱',
  ishidden: true,
  ischildren: false,
  children: [{
    path: '/myrecipequery',
    component: 'food/myrecipequery',
    name: 'myrecipequery',
    meta: {
      title: '查看我的食谱',
      icon: 'menus',
      noCache: true,
      perms: []
    }
  }],
  perms: [],
  hidden: true
}, {
  id: 9,
  path: '/nutrition',
  component: 'layout/Layout',
  redirect: '/nutrition',
  ishidden: false,
  ischildren: false,
  title: '营养知识库',
  children: [{
    path: '/nutrition',
    component: 'food/nutrition/index',
    name: 'nutrition',
    meta: {
      title: '营养知识库',
      icon: 'list',
      noCache: true,
      perms: []
    }
  }],
  perms: [{
    id: 37,
    pid: 9,
    title: '查询',
    value: 'system_article_list:GET'
  }, {
    id: 38,
    pid: 9,
    title: '新增',
    value: 'system_article_add:POST'
  }, {
    id: 39,
    pid: 9,
    title: '删除',
    value: 'system_article_del:GET'
  }, {
    id: 40,
    pid: 9,
    title: '编辑',
    value: 'system_article_edit:POST'
  }, {
    id: 41,
    pid: 9,
    title: '详情',
    value: 'system_article_details:GET'
  }],
  hidden: false
}, {
  id: 300,
  path: '/nutritionadd',
  component: 'layout/Layout',
  redirect: '/nutritionadd',
  ishidden: true,
  ischildren: false,
  title: '添加营养知识库',
  children: [{
    path: '/nutritionadd',
    component: 'food/nutrition/add',
    name: 'nutritionadd',
    meta: {
      title: '添加营养知识库',
      icon: 'list',
      noCache: true,
      perms: []
    }
  }],
  perms: [],
  hidden: true
}, {
  id: 301,
  path: '/nutritionview',
  component: 'layout/Layout',
  redirect: '/nutritionview',
  ishidden: true,
  ischildren: false,
  title: '查看营养知识库',
  children: [{
    path: '/nutritionview',
    component: 'food/nutrition/view',
    name: 'nutritionview',
    meta: {
      title: '查看营养知识库',
      icon: 'list',
      noCache: true,
      perms: []
    }
  }],
  perms: [],
  hidden: true
}, {
  id: 10,
  path: '/setting',
  component: 'layout/Layout',
  alwaysShow: true,
  meta: {
    title: '系统管理',
    icon: 'setting'
  },
  title: '系统管理',
  ishidden: false,
  ischildren: true,
  children: [
  // 三级菜单必须要用children
  {
    path: '/user',
    id: 11,
    component: 'setting/user',
    name: 'user',
    title: '食堂账户管理',
    hidden: true,
    meta: {
      title: '食堂账户管理',
      noCache: true,
      perms: []
    },
    perms: [{
      id: 200,
      title: '查询',
      value: 'manager_userList:GET'
    }, {
      id: 201,
      title: '新增',
      value: 'manager_addComUser:POST'
    }, {
      id: 202,
      title: '编辑',
      value: 'manager_editComUser:POST'
    }, {
      id: 203,
      title: '删除',
      value: 'manager_delComUser:GET'
    }]
  }, {
    path: '/rolefood',
    id: 12,
    component: 'setting/rolefood',
    name: 'role',
    title: '食堂角色管理',
    hidden: true,
    meta: {
      title: '食堂角色管理',
      noCache: true,
      perms: []
    },
    perms: [{
      id: 250,
      title: '查询',
      value: 'comUser_role_list:GET'
    }, {
      id: 251,
      title: '新增',
      value: 'comUser_role_add:POST'
    }, {
      id: 252,
      title: '编辑',
      value: 'comUser_role_edit:POST'
    }, {
      id: 253,
      title: '删除',
      value: 'comUser_role_del:GET'
    }]
  }, {
    path: '/account',
    id: 13,
    component: 'setting/account',
    name: 'account',
    title: '总后台账户管理',
    hidden: true,
    meta: {
      title: '总后台账户管理',
      noCache: true,
      perms: []
    },
    perms: [{
      id: 204,
      title: '查询',
      value: 'system_user_list:GET'
    }, {
      id: 205,
      title: '新增',
      value: 'system_user_add:POST'
    }, {
      id: 206,
      title: '编辑',
      value: 'system_user_edit:POST'
    }, {
      id: 207,
      title: '删除',
      value: 'system_user_del:GET'
    }]
  }, {
    path: '/role',
    id: 14,
    component: 'setting/role',
    name: 'role',
    title: '总后台角色管理',
    hidden: true,
    meta: {
      title: '总后台角色管理',
      noCache: true,
      perms: []
    },
    perms: [{
      id: 208,
      title: '查询',
      value: 'system_role_list:GET'
    }, {
      id: 209,
      title: '新增',
      value: 'system_role_add:POST'
    }, {
      id: 210,
      title: '编辑',
      value: 'system_role_edit:POST'
    }, {
      id: 211,
      title: '删除',
      value: 'system_role_del:GET'
    }]
  }],
  hidden: false
}];