var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-pages" }, [
    _c(
      "div",
      { staticClass: "app-slider" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "el-carousel",
          {
            staticClass: "carousels",
            attrs: {
              interval: 10000,
              arrow: "hover",
              height: _vm.clientHeight,
            },
          },
          _vm._l(_vm.images, function (item, index) {
            return _c(
              "el-carousel-item",
              { key: index },
              [
                _c("el-image", {
                  staticStyle: {
                    width: "100%",
                    height: "100%",
                    "object-fit": "cover",
                  },
                  attrs: { src: item.url, fit: "fit" },
                }),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        class: _vm.opened ? "flexLogin" : "flexLoginFull",
        staticStyle: {
          display: "flex",
          "align-items": "center",
          transition: "width 0.28s",
        },
      },
      [
        _c(
          "div",
          { staticClass: "login-center" },
          [
            _vm._m(1),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c(
              "el-form",
              {
                ref: "loginForm",
                staticClass: "login-form",
                attrs: {
                  model: _vm.loginForm,
                  rules: _vm.loginRules,
                  "auto-complete": "on",
                  "label-position": "left",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "username" } },
                  [
                    _c("el-input", {
                      ref: "username",
                      staticClass: "login-input",
                      attrs: {
                        placeholder: "请输入账号",
                        name: "username",
                        type: "text",
                        tabindex: "1",
                        "auto-complete": "on",
                      },
                      model: {
                        value: _vm.loginForm.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "username", $$v)
                        },
                        expression: "loginForm.username",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "Caps lock is On",
                      placement: "right",
                      manual: "",
                    },
                    model: {
                      value: _vm.capsTooltip,
                      callback: function ($$v) {
                        _vm.capsTooltip = $$v
                      },
                      expression: "capsTooltip",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "password" } },
                      [
                        _c("el-input", {
                          key: _vm.passwordType,
                          ref: "",
                          staticClass: "login-input",
                          attrs: {
                            type: _vm.passwordType,
                            placeholder: "请输入密码",
                            name: "password",
                            tabindex: "2",
                            "auto-complete": "on",
                          },
                          on: {
                            blur: function ($event) {
                              _vm.capsTooltip = false
                            },
                          },
                          nativeOn: {
                            keyup: [
                              function ($event) {
                                return _vm.checkCapslock($event)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleLogin($event)
                              },
                            ],
                          },
                          model: {
                            value: _vm.loginForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "password", $$v)
                            },
                            expression: "loginForm.password",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-checkbox",
                  {
                    staticStyle: { color: "#a0a0a0", margin: "0 0 20px 0" },
                    model: {
                      value: _vm.isRemenber,
                      callback: function ($$v) {
                        _vm.isRemenber = $$v
                      },
                      expression: "isRemenber",
                    },
                  },
                  [_vm._v("记住密码")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    directives: [
                      { name: "preventClick", rawName: "v-preventClick" },
                    ],
                    staticStyle: {
                      width: "100%",
                      "margin-bottom": "30px",
                      "background-color": "#0070C8",
                      "border-color": "#0070C8",
                      "border-radius": "10px",
                      "font-size": "18px",
                    },
                    attrs: { loading: _vm.loading, type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleLogin($event)
                      },
                    },
                  },
                  [_vm._v("登 录\n        ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slider-text" }, [
      _c("p", [_vm._v("欢迎访问，")]),
      _vm._v(" "),
      _c("p", [_vm._v("3C膳食营养分析管理系统")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-top" }, [
      _c("span", [_vm._v("登录")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-container" }, [
      _c("h3", { staticClass: "title" }, [_vm._v("3C膳食营养分析管理系统")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }