"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var forbidClick = null;
var _default = exports.default = {
  bind: function bind(e) {
    var el = e;
    var timer = null;
    forbidClick = function forbidClick() {
      el.disabled = true;
      el.classList.add('is-disabled');
      timer = setInterval(function () {
        if (window.currentResq.done) {
          clearInterval(timer);
          el.disabled = false;
          el.classList.remove('is-disabled');
        }
      }, 500);
    };
    el.addEventListener('click', forbidClick);
  },
  unbind: function unbind() {
    document.removeEventListener('click', forbidClick);
  }
};