"use strict";

var _interopRequireDefault = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _store = _interopRequireDefault(require("@/store"));
var _default = exports.default = {
  inserted: function inserted(el, binding, vnode) {
    var value = binding.value;
    var perms = _store.default.getters && _store.default.getters.perms;
    if (value && value instanceof Array && value.length > 0) {
      var permissions = value;
      var hasPermission = false;
      if (perms.indexOf('*') >= 0) {
        hasPermission = true;
      } else {
        hasPermission = perms.some(function (perm) {
          return permissions.includes(perm);
        });
      }
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("need perms! Like v-permission=\"['GET /aaa','POST /bbb']\"");
    }
  }
};