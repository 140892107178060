"use strict";

var _interopRequireDefault = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.accountadd = accountadd;
exports.accountdel = accountdel;
exports.accountedit = accountedit;
exports.accountlist = accountlist;
exports.addComUser = addComUser;
exports.agelist = agelist;
exports.checkToken = checkToken;
exports.delComUser = delComUser;
exports.editComUser = editComUser;
exports.queryuser = queryuser;
exports.refreshToken = refreshToken;
exports.roleadd = roleadd;
exports.roledel = roledel;
exports.roleedit = roleedit;
exports.rolelist = rolelist;
exports.rolesadd = rolesadd;
exports.rolesdel = rolesdel;
exports.rolesedit = rolesedit;
exports.roleslist = roleslist;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
function addComUser(data) {
  return (0, _request.default)({
    url: '/manager/addComUser',
    method: 'post',
    data: data
  });
}
function checkToken(data) {
  return (0, _request.default)({
    url: '/manager/checkToken',
    method: 'post',
    data: data
  });
}
function editComUser(data) {
  return (0, _request.default)({
    url: '/manager/editComUser',
    method: 'post',
    data: data
  });
}
function delComUser(query) {
  return (0, _request.default)({
    url: "/manager/delComUser/".concat(query.id),
    method: 'get',
    params: query
  });
}
function queryuser(query) {
  return (0, _request.default)({
    url: '/manager/userList',
    method: 'get',
    params: query
  });
}
function agelist(query) {
  return (0, _request.default)({
    url: '/cookbook/group/agelist',
    method: 'get',
    params: query
  });
}
function refreshToken(data) {
  return (0, _request.default)({
    url: '/manager/refreshToken',
    method: 'post',
    data: data
  });
}
function rolelist(query) {
  return (0, _request.default)({
    url: '/system/role/list',
    method: 'get',
    params: query
  });
}
function roleadd(data) {
  return (0, _request.default)({
    url: '/system/role/add',
    method: 'post',
    data: data
  });
}
function roleedit(data) {
  return (0, _request.default)({
    url: '/system/role/edit',
    method: 'post',
    data: data
  });
}
function roledel(query) {
  return (0, _request.default)({
    url: "/system/role/del/".concat(query.id),
    method: 'get',
    params: query
  });
}
function accountlist(query) {
  return (0, _request.default)({
    url: '/system/user/list',
    method: 'get',
    params: query
  });
}
function accountadd(data) {
  return (0, _request.default)({
    url: '/system/user/add',
    method: 'post',
    data: data
  });
}
function accountedit(data) {
  return (0, _request.default)({
    url: '/system/user/edit',
    method: 'post',
    data: data
  });
}
function accountdel(query) {
  return (0, _request.default)({
    url: '/system/user/del',
    method: 'get',
    params: query
  });
}
function roleslist(query) {
  return (0, _request.default)({
    url: '/comUser/role/list',
    method: 'get',
    params: query
  });
}
function rolesadd(data) {
  return (0, _request.default)({
    url: '/comUser/role/add',
    method: 'post',
    data: data
  });
}
function rolesedit(data) {
  return (0, _request.default)({
    url: '/comUser/role/edit',
    method: 'post',
    data: data
  });
}
function rolesdel(query) {
  return (0, _request.default)({
    url: '/comUser/role/del',
    method: 'get',
    params: query
  });
}