var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "external-container" }, [
    _vm.loading
      ? _c("div", { staticClass: "loading" }, [
          _c("div", { staticClass: "loading-text" }, [
            _vm._v("正在处理，请稍候..."),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }