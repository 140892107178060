"use strict";

var _interopRequireDefault = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.getUserId = getUserId;
exports.removeToken = removeToken;
exports.removeUserId = removeUserId;
exports.setToken = setToken;
exports.setUserId = setUserId;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var TokenKey = 'hhmc-token';
var userid = 'userid';
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
function getUserId() {
  return _jsCookie.default.get(userid);
}
function setUserId(id) {
  return _jsCookie.default.set(userid, id);
}
function removeUserId() {
  return _jsCookie.default.remove(userid);
}