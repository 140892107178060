var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "login-load", staticStyle: { background: "#041f2a" } },
      [
        _c("div", [
          _c("div", { staticClass: "loadEffect" }, [
            _c("div", [_c("span")]),
            _vm._v(" "),
            _c("div", [_c("span")]),
            _vm._v(" "),
            _c("div", [_c("span")]),
            _vm._v(" "),
            _c("div", [_c("span")]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "margin-top": "40px",
                color: "#ffffff",
              },
            },
            [
              _vm._v("登录中，请稍后 "),
              _c("span", { staticClass: "dot" }, [_vm._v(" ....")]),
            ]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }