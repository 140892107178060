"use strict";

var _interopRequireDefault = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _users = require("@/api/users");
var resqTimer = null;
window.currentResq = {
  done: true,
  config: {}
};
// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  withCredentials: false,
  // send cookies when cross-domain requests
  timeout: 600000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  clearTimeout(resqTimer);
  window.currentResq = {
    done: false,
    config: config
  };
  // 接口请求时长超过3s，则视为完成，不管请求结果成功或失败
  resqTimer = setTimeout(function () {
    window.currentResq = {
      done: true,
      config: {}
    };
  }, 3000);
  if (localStorage.getItem('token')) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['token'] = localStorage.getItem('token');
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  var _response$config = response.config,
    url = _response$config.url,
    method = _response$config.method,
    data = _response$config.data;
  var config = window.currentResq.config;
  if (config.url === url && config.method === method && config.data === data) {
    clearTimeout(resqTimer);
    window.currentResq.done = true;
  }
  // if the custom code is not 20000, it is judged as an error.
  if (res.code !== 200) {
    if (res.code == 401) {
      var refresh_token = localStorage.getItem('refresh_token');
      if (refresh_token) {
        var t = {
          token: refresh_token
        };
        (0, _users.refreshToken)(t).then(function (res) {
          var access_token = res.data.access_token;
          var refresh_token_ = res.data.refresh_token;
          localStorage.setItem('refresh_token', refresh_token_);
          localStorage.setItem('token', access_token);
          location.reload();
        });
      } else {
        (0, _elementUi.Message)({
          message: '身份验证失败!' + res.msg,
          type: 'error',
          duration: 5 * 1000
        });
      }
    } else if (res.code == 412 || res.code == 422) {
      (0, _elementUi.Message)({
        message: res.msg,
        type: 'warning',
        duration: 5 * 1000
      });
      _store.default.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    } else {
      (0, _elementUi.Message)({
        message: res.msg,
        type: 'warning',
        duration: 5 * 1000
      });
    }
    // if (res.status === 400) {
    //   // to re-login
    //   MessageBox.confirm('登录已超时，可以取消以停留在此页面上，或重新登录!', {
    //     confirmButtonText: '重新登录',
    //     cancelButtonText: '取消',
    //     type: 'warning',
    //     customClass: 'confirmTop'
    //   }).then(() => {
    //     store.dispatch('user/resetToken').then(() => {
    //       location.reload()
    //     })
    //   })
    // }
    // if (res.status == 4) {
    //   setToken('')
    //   Message({
    //     message: '无效的验证信息',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
    // } else {
    //   Message({
    //     message: res.msg,
    //     type: 'warning',
    //     duration: 5 * 1000
    //   })
    // }

    return Promise.reject(res.msg || 'error');
  } else {
    return res;
  }
}, function (error) {
  if (error && error.response) {
    switch (error.response.status) {
      case 404:
        error.message = '请求出错(404)';
        break;
      case 500:
        error.message = '服务器错误(500)';
        break;
      default:
        error.message = "\u8FDE\u63A5\u51FA\u9519(".concat(error.response.status, ")!");
    }
  }
  (0, _elementUi.Message)({
    message: error.message || error,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error.message);
});
var _default = exports.default = service;