"use strict";

var _interopRequireDefault = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _query = require("@/api/query");
var _vueGiantTree = _interopRequireDefault(require("vue-giant-tree"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ZtreePart',
  components: {
    tree: _vueGiantTree.default
  },
  props: {
    height: {
      type: Number,
      default: 0
    },
    parttype: {
      type: String,
      default: ''
    },
    frist: {
      default: true,
      type: Boolean
    }
  },
  data: function data() {
    return {
      part: [],
      name: '单位树',
      title: '',
      checked: true,
      build_img: require('@/assets/images/build.png'),
      setting: {
        check: {
          enable: false
        },
        data: {
          simpleData: {
            enable: true,
            idKey: 'partid',
            pIdKey: 'parentpart',
            rootPId: 0
          },
          key: {
            name: 'partname'
          }
        },
        view: {
          showIcon: true
        }
      }
    };
  },
  watch: {
    checked: {
      handler: function handler(n) {
        this.$emit('sub', n ? 1 : 0);
      }
    }
  },
  mounted: function mounted() {
    this.getpart();
  },
  methods: {
    getpart: function getpart() {
      var _this = this;
      (0, _query.partlist)().then(function (res) {
        _this.part = res.data;
        _this.part.forEach(function (item, index) {
          item.icon = _this.build_img;
        });
      }).catch(function (res) {
        _this.part = [];
      });
    },
    handleCreated: function handleCreated(ztreeObj) {
      this.ztreeObj = ztreeObj;
      ztreeObj.expandAll(true);
      if (this.frist) {
        var nodes = this.ztreeObj.transformToArray(this.ztreeObj.getNodes());
        var fristNode = nodes && nodes[0];
        if (fristNode) {
          ztreeObj.selectNode(fristNode);
          this.title = fristNode.partname;
          this.$emit('partid', fristNode.partid);
          this.$emit('partname', fristNode.partname);
        }
      }
    },
    getuser: function getuser(evt, treeId, treeNode) {
      this.title = treeNode.partname;
      this.$emit('partid', treeNode.partid);
      this.$emit('partname', treeNode.partname);
    },
    refresh: function refresh() {
      this.title = '';
      this.ztreeObj.cancelSelectedNode();
      this.$emit('partid', '');
      this.$emit('partname', '');
    }
  }
};