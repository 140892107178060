var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ztreePart", style: { height: _vm.height + "px" } },
    [
      _c(
        "el-card",
        { staticClass: "BoxCard" },
        [
          _c(
            "div",
            {
              staticStyle: { "text-align": "left", position: "relative" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [
                _vm._v("行政区\n        "),
                _vm.title != ""
                  ? _c(
                      "span",
                      {
                        staticStyle: { color: "#4caf50", "font-size": "14px" },
                      },
                      [_vm._v("（" + _vm._s(_vm.title) + "）")]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.refreshs
                ? _c(
                    "el-button",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "-10px",
                        padding: "2px 0px 3px 0",
                      },
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.refresh()
                        },
                      },
                    },
                    [_vm._v("刷新")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("tree", {
            attrs: { setting: _vm.setting, nodes: _vm.Province },
            on: { onClick: _vm.getuser, onCreated: _vm.handleCreated },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }