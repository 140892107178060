"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  install: function install(Vue) {
    // 禁止短时间内重复点击
    Vue.directive('preventClick', {
      inserted: function inserted(button, bind) {
        button.addEventListener('click', function () {
          if (!button.disabled) {
            button.disabled = true;
            setTimeout(function () {
              button.disabled = false;
            }, 600);
          }
        });
      }
    });
  }
};