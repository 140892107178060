"use strict";

var _interopRequireDefault = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/core-js/modules/es6.array.iterator.js");
require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/core-js/modules/es6.promise.js");
require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/core-js/modules/es6.object.assign.js");
require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireWildcard(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _controlClick = _interopRequireDefault(require("@/directive/controlClick"));
require("./icons");
require("./permission");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
var _repeat = _interopRequireDefault(require("@/directive/repeat.js"));
var _index2 = _interopRequireDefault(require("@/directive/permission/index.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _Query = _interopRequireDefault(require("@/components/Query"));
var _part = _interopRequireDefault(require("@/components/ZtreePart/part"));
var _Hamburger = _interopRequireDefault(require("@/components/ZtreePart/Hamburger"));
var _province = _interopRequireDefault(require("@/components/ZtreePart/province"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var echarts = _interopRequireWildcard(require("echarts"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
// 侧边栏目录树

_vue.default.prototype.$messages = _elementUi.Message;
_vue.default.prototype.$confirms = _elementUi.MessageBox.confirm;
_vue.default.config.productionTip = false;
_vue.default.prototype.$echarts = echarts;
_vue.default.component('Pagination', _Pagination.default);
_vue.default.component('Query', _Query.default);
_vue.default.component('ztree', _province.default);
_vue.default.component('Partztree', _part.default);
_vue.default.component('Hamburg', _Hamburger.default);
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium'
});
_vue.default.directive('permission', _index2.default);
_vue.default.directive('repeat', _repeat.default);
_vue.default.use(_controlClick.default);
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.prototype.$map = {
  _leaflet_id: -1
};
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});