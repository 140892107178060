"use strict";

var _interopRequireDefault = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.boundarytree = boundarytree;
exports.getboundary = getboundary;
exports.getheadimgbyusername = getheadimgbyusername;
exports.getuserbypartid = getuserbypartid;
exports.getuserinfobysysid = getuserinfobysysid;
exports.linzhang = linzhang;
exports.partlist = partlist;
exports.parttree = parttree;
exports.partunderuser = partunderuser;
exports.querypolygonbylnglat = querypolygonbylnglat;
exports.querypolygonbysysid = querypolygonbysysid;
exports.querysosdatabytime = querysosdatabytime;
exports.querytrack = querytrack;
exports.queryuserslastloc = queryuserslastloc;
exports.roletree = roletree;
exports.roletree_ = roletree_;
exports.touser = touser;
exports.toxunhuyuan = toxunhuyuan;
exports.underuser = underuser;
exports.xunhuyuan = xunhuyuan;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
function getuserbypartid(data) {
  return (0, _request.default)({
    url: '/admin/getuserbypartid?token=' + (0, _auth.getToken)(),
    method: 'post',
    data: data
  });
}
function linzhang(query) {
  return (0, _request.default)({
    url: '/task/usertree/linzhang?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function xunhuyuan(query) {
  return (0, _request.default)({
    url: '/ranger_train/usertree/xunhuyuan?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function partlist(query) {
  return (0, _request.default)({
    url: '/admin/part/all?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function querytrack(query) {
  return (0, _request.default)({
    url: '/location/querytrack2?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function queryuserslastloc(query) {
  return (0, _request.default)({
    url: '/location/queryuserslastloc?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function partunderuser(query) {
  return (0, _request.default)({
    url: '/admin/part/user/list?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function querysosdatabytime(query) {
  return (0, _request.default)({
    url: '/sos/querysosdatabytime?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function touser(query) {
  return (0, _request.default)({
    url: '/admin/user/list?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function toxunhuyuan(query) {
  return (0, _request.default)({
    url: '/ranger_train/xunhuyuan?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function getheadimgbyusername(query) {
  return (0, _request.default)({
    url: '/admin/getheadimgbyusername?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function parttree(query) {
  return (0, _request.default)({
    url: '/admin/part/tree?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function roletree(query) {
  return (0, _request.default)({
    url: '/admin/user/under/role/tree?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function querypolygonbylnglat(query) {
  return (0, _request.default)({
    url: '/geomap/dataapi/querybypoint?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function querypolygonbysysid(query) {
  return (0, _request.default)({
    url: '/spatialdb/querypolygonbysysid?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function getuserinfobysysid(query) {
  return (0, _request.default)({
    url: '/statisics/getuserinfobysysid?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function roletree_(query) {
  return (0, _request.default)({
    url: '/admin/roletree?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function boundarytree(query) {
  return (0, _request.default)({
    url: '/boundary/boundarytree',
    method: 'get',
    params: query
  });
}
function getboundary(data) {
  return (0, _request.default)({
    url: '/boundary/coordinate?token=' + (0, _auth.getToken)(),
    method: 'post',
    data: data
  });
}
function underuser(query) {
  return (0, _request.default)({
    url: '/admin/user/under/user?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}