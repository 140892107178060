"use strict";

var _interopRequireDefault = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _typeof2 = _interopRequireDefault(require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
var _layout = _interopRequireDefault(require("@/layout"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; } // progress bar
// progress bar style
_vue.default.use(_vueRouter.default);
_nprogress.default.configure({
  showSpinner: false
}); // NProgress Configuration

var constantRoutes = exports.constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/bigdata',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/bigdata/index'));
    });
  },
  hidden: true
}, {
  path: '/bigdata2',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/bigdata2/index'));
    });
  },
  hidden: true
}, {
  path: '/bigdata3',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/bigdata3/index'));
    });
  },
  hidden: true
}, {
  path: '/kc',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/kc'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/redirect/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/redirect/401'));
    });
  },
  hidden: true
}, {
  path: '/external/:page',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/external/index'));
    });
  },
  hidden: true
}
// {
//   path: '/',
//   component: Layout,
//   redirect: '/dashboard',
//   children: [{
//     path: 'dashboard',
//     component: () => import('@/views/dashboard/index'),
//     name: 'dashboard',
//     meta: {
//       title: '首页',
//       icon: 'dashboard',
//       affix: true
//     }
//   }]
// }
// {
//   path: '/food',
//   component: Layout,
//   redirect: '/editmenus',
//   children: [{
//     path: 'editmenus',
//     component: () => import('@/views/food/editmenus'),
//     name: 'editmenus',
//     meta: {
//       title: '菜品信息',
//       icon: 'menus',
//       affix: true
//     }
//   }],
//   hidden: true
// },
// {
//   path: '/food',
//   component: Layout,
//   redirect: '/material',
//   children: [{
//     path: 'material',
//     component: () => import('@/views/food/material'),
//     name: 'material',
//     meta: {
//       title: '食材库',
//       icon: 'shopping',
//       affix: true
//     }
//   }]
// }, {
//   path: '/food',
//   component: Layout,
//   redirect: '/target',
//   children: [{
//     path: 'target',
//     component: () => import('@/views/food/target'),
//     name: 'target',
//     meta: {
//       title: '配餐对象',
//       icon: 'one',
//       affix: true
//     }
//   }]
// }, {
//   path: '/food',
//   component: Layout,
//   redirect: '/recipe',
//   children: [{
//     path: 'recipe',
//     component: () => import('@/views/food/recipe'),
//     name: 'recipe',
//     meta: {
//       title: '本周食谱',
//       icon: 'form',
//       affix: true
//     }
//   }]
// },
// {
//   path: '/food',
//   component: Layout,
//   redirect: '/recipeedit',
//   children: [{
//     path: 'recipeedit',
//     component: () => import('@/views/food/recipeedit'),
//     name: 'recipeedit',
//     meta: {
//       title: '编辑食谱',
//       icon: 'menus',
//       affix: true
//     }
//   }],
//   hidden: true
// },
// {
//   path: '/food',
//   component: Layout,
//   redirect: '/myrecipe',
//   children: [{
//     path: 'myrecipe',
//     component: () => import('@/views/food/myrecipe'),
//     name: 'myrecipe',
//     meta: {
//       title: '我的食谱库',
//       icon: 'documentation',
//       affix: true
//     }
//   }]
// },
// {
//   path: '/food',
//   component: Layout,
//   redirect: '/myrecipequery',
//   children: [{
//     path: 'myrecipequery/:id(\\d+)',
//     component: () => import('@/views/food/recipequery'),
//     name: 'myrecipequery',
//     meta: {
//       title: '查看我的食谱库',
//       icon: 'form',
//       affix: true
//     }

//   }],
//   hidden: true
// },
// {
//   path: '/food',
//   component: Layout,
//   redirect: '/nutrition',
//   children: [{
//     path: 'nutrition',
//     component: () => import('@/views/food/nutrition'),
//     name: 'nutrition',
//     meta: {
//       title: '营养知识库',
//       icon: 'list',
//       affix: true
//     }
//   }]
// }
// {
//   path: '/setting',
//   component: Layout,
//   alwaysShow: true,
//   name: 'setting',
//   meta: { title: '系统管理', icon: 'setting' },
//   children: [
//     {
//       path: '/user',
//       name: 'user',
//       component: () => import('@/views/setting/user'),
//       meta: {
//         title: '账户管理',
//         noCache: true,
//         perms: []
//       }
//     }
//   ]
// }
// {
//   path: '/',
//   component: Layout,
//   redirect: '/dashboard',
//   children: [{
//     path: 'dashboard',
//     component: () => import('@/views/dashboard/index'),
//     name: 'dashboard',
//     meta: {
//       title: '首页',
//       icon: 'dashboard',
//       affix: true
//     }
//   }]
// }
];
var asyncRoutes = exports.asyncRoutes = [{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
// router.beforeEach((to, from, next) => {
//   const Hastoken = localStorage.getItem('token')
//   console.log(Hastoken)
//   if (to.path == '/404' || to.path == '/401' || to.path == '/login') {
//     next()
//   } else {
//     if (!Hastoken) {
//       next(`/login`)
//     } else {
//       next()
//     }
//   }
// })
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher;
}
var _default = exports.default = router;