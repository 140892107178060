"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  currentLayer: '',
  currentPage: null,
  geojson: []
};
var mutations = {
  SET_LAYER: function SET_LAYER(state, layer) {
    state.currentLayer = layer;
  },
  SET_PAGE: function SET_PAGE(state, page) {
    state.currentPage = page;
  },
  SET_GEOJSON: function SET_GEOJSON(state, geojson) {
    state.geojson = geojson;
  }
};
var actions = {
  setLayer: function setLayer(_ref, layer) {
    var commit = _ref.commit;
    commit('SET_LAYER', layer);
  },
  setPage: function setPage(_ref2, page) {
    var commit = _ref2.commit;
    commit('SET_PAGE', page);
  },
  setGeojson: function setGeojson(_ref3, geojson) {
    var commit = _ref3.commit;
    commit('SET_GEOJSON', geojson);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};