var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ztreePart", style: { height: _vm.height + "px" } },
    [
      _c(
        "el-card",
        { staticClass: "card" },
        [
          _c(
            "div",
            {
              staticStyle: { position: "relative" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.name) + "\n        "),
                _vm.title != ""
                  ? _c("span", { staticClass: "title" }, [
                      _vm._v("（" + _vm._s(_vm.title) + "）"),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "是否包含下级",
                    placement: "bottom",
                  },
                },
                [
                  _c("el-checkbox", {
                    staticClass: "check",
                    model: {
                      value: _vm.checked,
                      callback: function ($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("tree", {
            attrs: { setting: _vm.setting, nodes: _vm.part },
            on: { onClick: _vm.getuser, onCreated: _vm.handleCreated },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }