var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "errPage-container",
      staticStyle: { margin: "0", padding: "130px", "padding-top": "25vh" },
    },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("h1", { staticClass: "text-jumbo text-ginormous" }, [
              _vm._v("\n        Oops!\n      "),
            ]),
            _vm._v(" "),
            _c("h2", [_vm._v("无法获取到您的个人信息")]),
            _vm._v(" "),
            _c("h6", [_vm._v("你没有权限去该页面")]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("img", {
              attrs: {
                src: _vm.errGif,
                width: "200",
                height: "260",
                alt: "Girl has dropped her ice cream.",
              },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            title: "随便看",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [_c("img", { staticClass: "pan-img", attrs: { src: _vm.ewizardClap } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }