"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filter1 = filter1;
exports.filter2 = filter2;
exports.filter3 = filter3;
function filter1(status) {
  var statusMap = {
    1: '全部',
    2: '主食',
    3: '糕点',
    4: '小吃',
    5: '荤菜',
    6: '素菜',
    7: '汤类',
    8: '饮品',
    9: '水果'
  };
  return statusMap[status];
}
function filter2(status) {
  var statusMap = {
    1: '全部',
    2: '早餐',
    3: '上午加餐',
    4: '午餐',
    5: '下午加餐',
    6: '晚餐',
    7: '夜宵'
  };
  return statusMap[status];
}
function filter3(status) {
  var statusMap = {
    0: '全部',
    1: '春季',
    2: '夏季',
    3: '秋季',
    4: '冬季'
  };
  return statusMap[status];
}