"use strict";

var _interopRequireDefault = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adduser = adduser;
exports.changePassword = changePassword;
exports.deluser = deluser;
exports.editself = editself;
exports.edituser = edituser;
exports.edituserforrole = edituserforrole;
exports.getInfo = getInfo;
exports.getrolebyuserid = getrolebyuserid;
exports.getunderbyuserid = getunderbyuserid;
exports.getuserbyid = getuserbyid;
exports.getuserbyusername = getuserbyusername;
exports.import_user_save = import_user_save;
exports.infouser = infouser;
exports.jumpweb = jumpweb;
exports.login = login;
exports.loginbybgtoken = loginbybgtoken;
exports.loginbywebtoken = loginbywebtoken;
exports.logout = logout;
exports.manyuser = manyuser;
exports.queryusersinfo = queryusersinfo;
exports.restpwd = restpwd;
exports.uploadPath = void 0;
exports.user_management = user_management;
exports.user_management_zone = user_management_zone;
exports.userlist = userlist;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
function login(data) {
  return (0, _request.default)({
    url: '/manager/adminLogin',
    method: 'post',
    data: data
  });
}
function getInfo(query) {
  return (0, _request.default)({
    url: "/system/user/details/".concat(query.id),
    method: 'get'
  });
}
function getuserbyusername(query) {
  return (0, _request.default)({
    url: '/admin/user/infobyname?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function logout() {
  return (0, _request.default)({
    url: '/admin/self/logout?token=' + (0, _auth.getToken)(),
    method: 'post'
  });
}
function changePassword(data) {
  return (0, _request.default)({
    url: '/admin/self/manager/edit_pwd',
    method: 'post',
    data: data
  });
}
function userlist(query) {
  return (0, _request.default)({
    url: '/admin/user/list?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function edituser(data) {
  return (0, _request.default)({
    url: '/admin/user/edit?token=' + (0, _auth.getToken)(),
    method: 'post',
    data: data
  });
}
function adduser(data) {
  return (0, _request.default)({
    url: '/admin/user/add?token=' + (0, _auth.getToken)(),
    method: 'post',
    data: data
  });
}
function deluser(query) {
  return (0, _request.default)({
    url: '/admin/user/delete?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function infouser(query) {
  return (0, _request.default)({
    url: '/admin/user/info?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}

// ///
function getrolebyuserid(data) {
  return (0, _request.default)({
    url: '/admin/getrolebyuserid?token=' + (0, _auth.getToken)(),
    method: 'post',
    data: data
  });
}
function edituserforrole(data) {
  return (0, _request.default)({
    url: '/admin/edituserforrole?token=' + (0, _auth.getToken)(),
    method: 'post',
    data: data
  });
}
function queryusersinfo(query) {
  return (0, _request.default)({
    url: '/user/queryusersinfo?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function getunderbyuserid(data) {
  return (0, _request.default)({
    url: '/admin/user/under/user?token=' + (0, _auth.getToken)(),
    method: 'post',
    data: data
  });
}
function editself(data) {
  return (0, _request.default)({
    url: '/admin/self/edit?token=' + (0, _auth.getToken)(),
    method: 'post',
    data: data
  });
}
function getuserbyid(query) {
  return (0, _request.default)({
    url: '/admin/user/info?token=' + (0, _auth.getToken)(),
    method: 'get',
    params: query
  });
}
function import_user_save(data) {
  return (0, _request.default)({
    url: '/admin/import_user_save?token=' + (0, _auth.getToken)(),
    method: 'post',
    data: data
  });
}
function restpwd(data) {
  return (0, _request.default)({
    url: '/admin/restpwd?token=' + (0, _auth.getToken)(),
    method: 'post',
    data: data
  });
}
function user_management(data) {
  return (0, _request.default)({
    url: '/boundary/user_management?token=' + (0, _auth.getToken)(),
    method: 'post',
    data: data
  });
}
function user_management_zone(data) {
  return (0, _request.default)({
    url: '/boundary/user_management_zone?token=' + (0, _auth.getToken)(),
    method: 'post',
    data: data
  });
}
function loginbybgtoken(query) {
  return (0, _request.default)({
    url: '/bigdata/loginbybgtoken',
    method: 'get',
    params: query
  });
}
function loginbywebtoken(query) {
  return (0, _request.default)({
    url: '/bigdata/loginbywebtoken',
    method: 'get',
    params: query
  });
}
function manyuser(data) {
  return (0, _request.default)({
    url: '/admin/user/delete/many?token=' + (0, _auth.getToken)(),
    method: 'post',
    data: data
  });
}
function jumpweb(query) {
  return (0, _request.default)({
    url: '/jump/web',
    method: 'get',
    params: query
  });
}
var uploadPath = exports.uploadPath = process.env.VUE_APP_BASE_API + 'admin/addheadimg?token=' + (0, _auth.getToken)();