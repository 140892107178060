var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "fullScreen",
      staticClass: "page-screen",
      class: { "board-page": _vm.board, "bg-page": !_vm.board },
    },
    [
      _vm.list.length == 0
        ? _c("img", {
            staticClass: "emp",
            style: { left: _vm.board ? "40%" : "52%" },
            attrs: { src: require("./images/10.png"), alt: "" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: { board: _vm.board, bg: !_vm.board },
          style: _vm.computedStyle,
        },
        [
          _c("div", { staticClass: "bigdata" }, [
            _c("div", { staticClass: "bigdata-main" }, [
              _c("div", { staticClass: "bigdata-main-left" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "bigdata-main-left-center" }, [
                  _c("div", { staticClass: "bigdata-main-left-center-1" }, [
                    !_vm.flag
                      ? _c("div", [
                          _vm._m(1),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "bigdata-main-left-center-1-img",
                            attrs: { src: require("./images/4.png"), alt: "" },
                          }),
                          _vm._v(" "),
                          _vm._m(2),
                        ])
                      : _c(
                          "div",
                          { staticClass: "bigdata-main-left-center-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "bigdata-main-left-center-3" },
                              [
                                _c("div", [_vm._v("膳食营养分析结果一览")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "rgba(163, 163, 163, 1)",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.base.member_title))]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "transition",
                              { attrs: { name: "el-fade-in-linear" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "bigdata-main-left-center-4" },
                                  [
                                    _c("div", [_vm._v("整体评价")]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.dailyMealsData.score)
                                        ),
                                      ]),
                                      _vm._v("分"),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "transition",
                              { attrs: { name: "el-fade-in-linear" } },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.load,
                                        expression: "load",
                                      },
                                    ],
                                    staticClass: "bigdata-main-left-center-5",
                                    attrs: {
                                      "element-loading-text": "分析中",
                                      "element-loading-spinner":
                                        "el-icon-loading",
                                      "element-loading-background":
                                        "rgba(0, 0, 0, 0.8)",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "bigdata-main-left-center-5-0",
                                      },
                                      [_vm._v("营养素分析")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "305px",
                                          overflow: "hidden",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "bigdata-main-left-center-5-1",
                                          },
                                          [
                                            _c("div", [_vm._v("营养素")]),
                                            _vm._v(" "),
                                            _c("div", [_vm._v("实际摄入")]),
                                            _vm._v(" "),
                                            _c("div", [_vm._v("溢出范围")]),
                                            _vm._v(" "),
                                            _c("div", [_vm._v("推荐量")]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.dailyMealsData.analysis,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass:
                                                  "bigdata-main-left-center-5-2",
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(item.name) +
                                                      "\n                        "
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(item.intake) +
                                                      "\n                        "
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      position: "relative",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          display:
                                                            "inline-block",
                                                          width: "50px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.overflow
                                                          ) + "% "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    item.grade == "低下"
                                                      ? _c("img", {
                                                          staticStyle: {
                                                            width: "10px",
                                                            position:
                                                              "absolute",
                                                            right: "8px",
                                                          },
                                                          attrs: {
                                                            src: require("./images/5.png"),
                                                            alt: "",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item.grade == "充裕"
                                                      ? _c("img", {
                                                          staticStyle: {
                                                            width: "18px",
                                                            position:
                                                              "absolute",
                                                            right: "4px",
                                                          },
                                                          attrs: {
                                                            src: require("./images/6.png"),
                                                            alt: "",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item.grade == "超标"
                                                      ? _c("img", {
                                                          staticStyle: {
                                                            width: "10px",
                                                            position:
                                                              "absolute",
                                                            right: "8px",
                                                          },
                                                          attrs: {
                                                            src: require("./images/7.png"),
                                                            alt: "",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(item.standard) +
                                                      "\n                        "
                                                  ),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "transition",
                              { attrs: { name: "el-fade-in-linear" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "bigdata-main-left-center-6" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "bigdata-main-left-center-6-0",
                                      },
                                      [
                                        _c("span", [_vm._v("饮食提醒")]),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "rgba(102, 102, 102, 1)",
                                              "font-size": "14px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "基于《中国居民膳食指南》标准"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "bigdata-main-left-center-6-1",
                                      },
                                      [
                                        _vm.calories
                                          ? _c("div", [
                                              _c("i", { staticClass: "crl" }),
                                              _vm._v(" "),
                                              _vm.type == "breakfast"
                                                ? _c("span", [_vm._v("早餐")])
                                                : _vm._e(),
                                              _vm.type == "lunch"
                                                ? _c("span", [_vm._v("午餐")])
                                                : _vm._e(),
                                              _vm.type == "supper"
                                                ? _c("span", [_vm._v("晚餐")])
                                                : _vm._e(),
                                              _vm._v(
                                                "推荐摄入" +
                                                  _vm._s(
                                                    _vm.calories.standard
                                                  ) +
                                                  "千卡，\n                        "
                                              ),
                                              _vm.calories.grade == "低下"
                                                ? _c("span", [
                                                    _vm._v(
                                                      "少吃了" +
                                                        _vm._s(
                                                          Math.abs(
                                                            _vm.calories
                                                              .overflow
                                                          )
                                                        ) +
                                                        "%"
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.calories.grade == "超标"
                                                ? _c("span", [
                                                    _vm._v(
                                                      "多吃了" +
                                                        _vm._s(
                                                          _vm.calories.overflow
                                                        ) +
                                                        "%"
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.protein &&
                                        _vm.fat &&
                                        _vm.carbohydrate
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-top": "8px",
                                                },
                                              },
                                              [
                                                _c("i", { staticClass: "crl" }),
                                                _vm._v(" "),
                                                _vm.protein.grade == "低下"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "蛋白质摄入不足，"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.protein.grade == "超标"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "蛋白质摄入偏高，"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.fat.grade == "低下"
                                                  ? _c("span", [
                                                      _vm._v("脂肪摄入不足，"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.fat.grade == "超标"
                                                  ? _c("span", [
                                                      _vm._v("脂肪摄入偏高，"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.carbohydrate.grade == "低下"
                                                  ? _c("span", [
                                                      _vm._v("碳水摄入不足"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.carbohydrate.grade == "超标"
                                                  ? _c("span", [
                                                      _vm._v("碳水摄入偏高"),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(3),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bigdata-main-right" }, [
                _vm.board
                  ? _c("div", { staticClass: "bigdata-header" }, [
                      _c("div", { staticClass: "bigdata-header-title" }, [
                        _vm._v("3C营养食谱看板"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "bigdata-main-right-main",
                    attrs: {
                      "element-loading-text": "食谱加载中",
                      "element-loading-spinner": "el-icon-loading",
                      "element-loading-background": "rgba(0, 0, 0, 0)",
                    },
                  },
                  [
                    _c("div", { staticClass: "bigdata-main-right-top" }, [
                      _c("div", [
                        _vm.type == "breakfast"
                          ? _c(
                              "span",
                              { staticClass: "bigdata-main-right-top-1" },
                              [_vm._v("今日早餐")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.type == "lunch"
                          ? _c(
                              "span",
                              { staticClass: "bigdata-main-right-top-1" },
                              [_vm._v("今日午餐")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.type == "supper"
                          ? _c(
                              "span",
                              { staticClass: "bigdata-main-right-top-1" },
                              [_vm._v("今日晚餐")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "bigdata-main-right-top-2" },
                          [
                            _vm._v(
                              "共" +
                                _vm._s((_vm.list && _vm.list.length) || 0) +
                                "种菜品"
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.board
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                color: "rgba(255, 87, 51, 1)",
                                "font-size": "34px",
                              },
                            },
                            [
                              _vm._v(
                                "\n                严格执行《中华人民共和国反食品浪费法》 拒绝浪费，从我做起！ 鼓励光盘行动，倡导文明进餐！\n              "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.board
                        ? _c("div", [
                            _vm.checkList.length
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "rest",
                                    on: { click: _vm.rest },
                                  },
                                  [_vm._v("重置")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "bigdata-main-right-top-3",
                                on: {
                                  click: function ($event) {
                                    return _vm.analysis()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  已选 " +
                                    _vm._s(_vm.checkList.length) +
                                    " 种菜品，开始分析\n                "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "bigdata-main-right-main-list" },
                      [
                        _c(
                          "vue-seamless-scroll",
                          {
                            ref: "seamlessScroll",
                            staticClass: "seamless-warp",
                            attrs: {
                              data: _vm.board ? _vm.list : [],
                              "class-option": _vm.classOption,
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                staticClass: "bigdata-checkbox",
                                attrs: { fill: "#43CF7C" },
                                model: {
                                  value: _vm.checkList,
                                  callback: function ($$v) {
                                    _vm.checkList = $$v
                                  },
                                  expression: "checkList",
                                },
                              },
                              _vm._l(_vm.list, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item.dishes_id,
                                    staticClass:
                                      "bigdata-main-right-main-list-box",
                                    class: {
                                      act:
                                        _vm.checkList.indexOf(item.dishes_id) !=
                                        -1
                                          ? true
                                          : false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.cg(item.dishes_id)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "bigdata-main-right-main-list-box-top",
                                      },
                                      [
                                        _c("img", {
                                          attrs: { src: item.dis_img, alt: "" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "bigdata-main-right-main-list-box-top-check",
                                          },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  label: item.dishes_id,
                                                },
                                              },
                                              [_vm._v(_vm._s(""))]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "bigdata-main-right-main-list-box-top-name",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.dishes_name) + " "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.weight) + "g/份"
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            item.price > 0
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.price > 0
                                                        ? item.price + "元/份"
                                                        : "--/元"
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "bigdata-main-right-main-list-box-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "bigdata-main-right-main-list-box-header",
                                          },
                                          [
                                            _vm._v(
                                              "\n                      食材用量：" +
                                                _vm._s(item.ilist) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "bigdata-main-right-main-list-box-1",
                                          },
                                          [
                                            _c("span", [_vm._v("营养素名称")]),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v("含量（每份）"),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "bigdata-main-right-main-list-box-main",
                                          },
                                          [
                                            _c("div", [
                                              _c("span", [_vm._v("热量")]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.calories) + "kcal"
                                                ),
                                              ]),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c("span", [
                                                _vm._v("碳水化合物"),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.carbohydrate) +
                                                    "g"
                                                ),
                                              ]),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c("span", [_vm._v("蛋白质")]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.protein) + "g"
                                                ),
                                              ]),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c("span", [_vm._v("脂肪")]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(_vm._s(item.fat) + "g"),
                                              ]),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c("span", [_vm._v("钠")]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.calcium) + "mg"
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bigdata-main-left-top" }, [
      _c("div", [_vm._v("致格食谱分析系统")]),
      _vm._v(" "),
      _c("div", [_vm._v("NUTRITIONAL ANALYSIS")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { display: "inline-block" } }, [
      _c("div", { staticStyle: { "margin-bottom": "6px" } }, [
        _vm._v("点选右侧菜品"),
      ]),
      _vm._v(" "),
      _c("div", [_vm._v("用餐评分一目了然")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bigdata-main-left-center-1-2" }, [
      _c("p", { staticClass: "text1" }, [
        _vm._v("您选择了5种菜品，最终评价75分"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text2" }, [_vm._v("您的蛋白质摄入量超标")]),
      _vm._v(" "),
      _c("p", { staticClass: "text3" }, [_vm._v("碳水化合物摄入量不足")]),
      _vm._v(" "),
      _c("p", { staticClass: "text4" }, [_vm._v("建议增加粗粮摄入")]),
      _vm._v(" "),
      _c("p", { staticClass: "text5" }, [_vm._v("今日摄入食物种类超12种")]),
      _vm._v(" "),
      _c("p", { staticClass: "text6" }, [
        _vm._v("经典均衡营养，继续保持哦~ "),
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "24px",
              "margin-left": "10px",
              color: "rgba(169, 178, 194, 1)",
            },
          },
          [_vm._v("减油减盐！")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bigdata-main-left-bottom" }, [
      _c("div", { staticClass: "bigdata-main-left-bottom-1" }, [
        _c("img", {
          staticClass: "bigdata-main-left-bottom-1-img",
          attrs: { src: require("./images/2.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "bigdata-main-left-bottom-2" }, [
          _vm._v("扫一扫，营养分析随心看"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bigdata-main-left-bottom-3" }, [
          _c("img", { attrs: { src: require("./images/3.png"), alt: "" } }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }