import { render, staticRenderFns } from "./kc.vue?vue&type=template&id=271858d8"
import script from "./kc.vue?vue&type=script&lang=js"
export * from "./kc.vue?vue&type=script&lang=js"
import style0 from "./kc.vue?vue&type=style&index=0&id=271858d8&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('271858d8')) {
      api.createRecord('271858d8', component.options)
    } else {
      api.reload('271858d8', component.options)
    }
    module.hot.accept("./kc.vue?vue&type=template&id=271858d8", function () {
      api.rerender('271858d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/login/kc.vue"
export default component.exports