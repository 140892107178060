"use strict";

var _interopRequireDefault = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _cryptoJs = _interopRequireDefault(require("crypto-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Login',
  data: function data() {
    return {
      isRemenber: false,
      opened: false,
      clientHeight: '',
      images: [{
        url: require('@/assets/images/1.jpg')
      }],
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        password: [{
          required: true,
          trigger: 'blur',
          message: '密码不能为空'
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    this.clientHeight = document.body.clientHeight + 'px';
  },
  mounted: function mounted() {
    var _this = this;
    window.addEventListener('resize', function () {
      _this.clientHeight = document.body.clientHeight + 'px';
    }, true);
    this.getRemenber();
    this.opened = true;
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  methods: {
    checkCapslock: function checkCapslock() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        shiftKey = _ref.shiftKey,
        key = _ref.key;
      if (key && key.length === 1) {
        if (shiftKey && key >= 'a' && key <= 'z' || !shiftKey && key >= 'A' && key <= 'Z') {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }
      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    showPwd: function showPwd() {
      var _this2 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    getRemenber: function getRemenber() {
      this.loginForm.username = _jsCookie.default.get('userName') ? _jsCookie.default.get('userName') : '';
      var enPassword = _jsCookie.default.get('userPwd') ? _jsCookie.default.get('userPwd') : '';
      if (enPassword) {
        // 对密码进行解密
        this.loginForm.password = _cryptoJs.default.AES.decrypt(enPassword, 'user123!').toString(_cryptoJs.default.enc.Utf8);
        console.log(this.loginForm, 'enPassword');
        // 将是否记住密码置为true
        this.isRemenber = true;
      } else {
        this.loginForm.password = '';
      }
    },
    setRemenber: function setRemenber() {
      if (this.isRemenber) {
        //添加cookie
        _jsCookie.default.set('userName', this.loginForm.username, {
          expires: 30
        });
        //使用crypto-js进行加密（需要npm加载后引入） 并存储到cookie中 此处user123! 为秘钥 
        _jsCookie.default.set('userPwd', _cryptoJs.default.AES.encrypt(this.loginForm.password, 'user123!'), {
          expires: 30 // 存储30天
        });
      } else {
        // 删除cookie
        _jsCookie.default.remove('userName');
        _jsCookie.default.remove('userPwd');
      }
    },
    handleLogin: function handleLogin() {
      var _this3 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          _this3.$store.dispatch('user/login', _this3.loginForm).then(function () {
            _this3.setRemenber();
            _this3.$router.push({
              path: '/menus'
            });
            _this3.loading = false;
          }).catch(function (res) {
            //  this.$messages.error(res)
            _this3.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};