var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "fullScreen", staticClass: "page-screen" }, [
    _c("div", { staticClass: "bg", style: _vm.computedStyle }, [
      _c("div", { staticClass: "bigdata" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "bigdata-main" }, [
          _c("div", { staticClass: "bigdata-main-left" }, [
            _c("div", { staticClass: "bigdata-main-left-top-0" }, [
              _c("p", [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _c("img", {
                attrs: { src: require("./images/tip.png"), alt: "" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bigdata-main-left-top" }, [
              _c("img", {
                staticStyle: { width: "25px" },
                attrs: { src: require("./images/point.png"), alt: "" },
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.today) + " " + _vm._s(_vm.week))]),
            ]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _vm.flag
              ? _c(
                  "div",
                  {
                    staticClass: "bigempty",
                    staticStyle: { "margin-top": "20px" },
                  },
                  [
                    _vm._l(14, function (i) {
                      return _c("div")
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("今日未开餐")]),
                    _vm._v(" "),
                    _c("img", {
                      staticStyle: {
                        width: "100%",
                        height: "5px",
                        "margin-top": "15px",
                      },
                      attrs: { src: require("./images/line.png"), alt: "" },
                    }),
                  ],
                  2
                )
              : _c("div", [
                  _c(
                    "div",
                    { staticStyle: { height: "785px", overflow: "hidden" } },
                    [
                      _c(
                        "div",
                        { staticClass: "big-cookbook" },
                        [
                          _c(
                            "vue-seamless-scroll",
                            {
                              ref: "seamlessScroll",
                              staticClass: "seamless-warp",
                              attrs: {
                                data: _vm.cookbook.breakfast,
                                "class-option": _vm.classOption,
                              },
                            },
                            [
                              _vm.cookbook.breakfast &&
                              _vm.cookbook.breakfast.length
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-top": "20px" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "bigdata-tips" },
                                        [
                                          _vm._v(
                                            "\n                      早餐（07:20）\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "5px" },
                                        },
                                        [
                                          _vm.cookbook.breakfast &&
                                          _vm.cookbook.breakfast.length
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "big-breakfast",
                                                },
                                                _vm._l(
                                                  _vm.cookbook.breakfast,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "bigdata-table-main",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "radius_",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "20px",
                                                            left: "-3px",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("i", {
                                                          staticClass:
                                                            "radius_",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "20px",
                                                            left: "-3px",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("i", {
                                                          staticClass:
                                                            "radius_",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "20px",
                                                            right: "-3px",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("i", {
                                                          staticClass:
                                                            "radius_",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "20px",
                                                            right: "-3px",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "bigdata-table-title",
                                                            staticStyle: {
                                                              width: "170px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  item.dishes_name
                                                                ) +
                                                                "（" +
                                                                _vm._s(
                                                                  item.weight
                                                                ) +
                                                                "g）\n                          "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "bigdata-table-title",
                                                            staticStyle: {
                                                              width: "135px",
                                                            },
                                                          },
                                                          _vm._l(
                                                            item.ingredients,
                                                            function (
                                                              itm,
                                                              idx
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: idx },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      itm.name
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "bigdata-table-title",
                                                            staticStyle: {
                                                              width: "150px",
                                                            },
                                                          },
                                                          _vm._l(
                                                            item.ingredients,
                                                            function (
                                                              itm,
                                                              idx
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: idx },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      itm.weight
                                                                    ) + "g"
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "edata" },
                                                [_vm._v("未开餐")]
                                              ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.cookbook.lunch && _vm.cookbook.lunch.length
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-top": "20px" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "bigdata-tips" },
                                        [
                                          _vm._v(
                                            "\n                      午餐（11:30）\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "5px" },
                                        },
                                        [
                                          _vm.cookbook.lunch &&
                                          _vm.cookbook.lunch.length
                                            ? _c(
                                                "div",
                                                { staticClass: "big-lunch" },
                                                _vm._l(
                                                  _vm.cookbook.lunch,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "bigdata-table-main",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "radius_",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "20px",
                                                            left: "-3px",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("i", {
                                                          staticClass:
                                                            "radius_",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "20px",
                                                            left: "-3px",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("i", {
                                                          staticClass:
                                                            "radius_",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "20px",
                                                            right: "-3px",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("i", {
                                                          staticClass:
                                                            "radius_",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "20px",
                                                            right: "-3px",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "bigdata-table-title",
                                                            staticStyle: {
                                                              width: "170px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  item.dishes_name
                                                                ) +
                                                                "（" +
                                                                _vm._s(
                                                                  item.weight
                                                                ) +
                                                                "g）\n                          "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "bigdata-table-title",
                                                            staticStyle: {
                                                              width: "135px",
                                                            },
                                                          },
                                                          _vm._l(
                                                            item.ingredients,
                                                            function (
                                                              itm,
                                                              idx
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: idx },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      itm.name
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "bigdata-table-title",
                                                            staticStyle: {
                                                              width: "150px",
                                                            },
                                                          },
                                                          _vm._l(
                                                            item.ingredients,
                                                            function (
                                                              itm,
                                                              idx
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: idx },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      itm.weight
                                                                    ) + "g"
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "edata" },
                                                [_vm._v("未开餐")]
                                              ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.cookbook.supper && _vm.cookbook.supper.length
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-top": "20px" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "bigdata-tips" },
                                        [
                                          _vm._v(
                                            "\n                      晚餐（17:30）\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "5px" },
                                        },
                                        [
                                          _vm.cookbook.supper &&
                                          _vm.cookbook.supper.length
                                            ? _c(
                                                "div",
                                                { staticClass: "big-supper" },
                                                _vm._l(
                                                  _vm.cookbook.supper,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "bigdata-table-main",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "radius_",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "20px",
                                                            left: "-3px",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("i", {
                                                          staticClass:
                                                            "radius_",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "20px",
                                                            left: "-3px",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("i", {
                                                          staticClass:
                                                            "radius_",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "20px",
                                                            right: "-3px",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("i", {
                                                          staticClass:
                                                            "radius_",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "20px",
                                                            right: "-3px",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "bigdata-table-title",
                                                            staticStyle: {
                                                              width: "170px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  item.dishes_name
                                                                ) +
                                                                "（" +
                                                                _vm._s(
                                                                  item.weight
                                                                ) +
                                                                "g）\n                          "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "bigdata-table-title",
                                                            staticStyle: {
                                                              width: "135px",
                                                            },
                                                          },
                                                          _vm._l(
                                                            item.ingredients,
                                                            function (
                                                              itm,
                                                              idx
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: idx },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      itm.name
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "bigdata-table-title",
                                                            staticStyle: {
                                                              width: "150px",
                                                            },
                                                          },
                                                          _vm._l(
                                                            item.ingredients,
                                                            function (
                                                              itm,
                                                              idx
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: idx },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      itm.weight
                                                                    ) + "g"
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass: "edata",
                                                  staticStyle: {
                                                    height: "190px",
                                                    "line-height": "190px",
                                                  },
                                                },
                                                [_vm._v("未开餐")]
                                              ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("img", {
                    staticStyle: {
                      width: "100%",
                      height: "5px",
                      "margin-top": "15px",
                    },
                    attrs: { src: require("./images/line.png"), alt: "" },
                  }),
                ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bigdata-main-center" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "bigdata-main-center-main" }, [
              _c("div", { staticClass: "bigdata-main-center-main-1" }, [
                _c("div", { staticClass: "bigdata-main-center-main-1-1" }, [
                  _c("img", {
                    attrs: { src: require("./images/cr.png"), alt: "" },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "bigdata-main-center-main-1-1-text" },
                    [_vm._v("食谱评分")]
                  ),
                  _vm._v(" "),
                  _vm.score == 0
                    ? _c("span", { staticClass: "scores" }, [_vm._v("---")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    ref: "myChart",
                    staticStyle: {
                      width: "200px",
                      height: "200px",
                      "margin-left": "10px",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "bigdata-main-center-main-1-2" }, [
                  _c("img", {
                    attrs: { src: require("./images/cr1.png"), alt: "" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "at" }, [_vm._v("当前食谱评价")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ah" }, [
                    _c(
                      "div",
                      {
                        staticClass: "bigdata-main-center-ah",
                        class: {
                          ahactive:
                            _vm.scoreGrade.indexOf("F") != -1 ? true : false,
                        },
                      },
                      [_vm._v("F\n                  ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bigdata-main-center-ah",
                        class: {
                          ahactive:
                            _vm.scoreGrade.indexOf("C") != -1 ? true : false,
                        },
                      },
                      [_vm._v("C\n                  ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bigdata-main-center-ah",
                        class: {
                          ahactive:
                            _vm.scoreGrade.indexOf("B") != -1 ? true : false,
                        },
                      },
                      [_vm._v("B\n                  ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bigdata-main-center-ah",
                        class: {
                          ahactive:
                            _vm.scoreGrade.indexOf("A") != -1 ? true : false,
                        },
                      },
                      [_vm._v("A\n                  ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bigdata-main-center-ah",
                        class: {
                          ahactive:
                            _vm.scoreGrade.indexOf("S") != -1 ? true : false,
                        },
                      },
                      [_vm._v("S\n                  ")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "bigdata-main-center-main-1-3" }, [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "padding-left": "15px" },
                    },
                    [
                      _c("div", {
                        ref: "myChart1",
                        staticStyle: { width: "260px", height: "250px" },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        ref: "myChart2",
                        staticStyle: { width: "250px", height: "250px" },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        ref: "myChart3",
                        staticStyle: { width: "280px", height: "250px" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.flag
                    ? _c("div", { staticClass: "ech" }, [
                        _c("span", [_vm._v("暂无数据")]),
                        _c("span", [_vm._v("暂无数据")]),
                        _c("span", [_vm._v("暂无数据")]),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                !_vm.flag
                  ? _c("div", { staticClass: "bigdata-main-center-main-1-4" }, [
                      _c("span", [_vm._v(_vm._s(_vm.decide()))]),
                    ])
                  : _c(
                      "div",
                      {
                        staticClass: "bigdata-main-center-main-1-4",
                        staticStyle: { "text-align": "center", height: "70px" },
                      },
                      [
                        _vm._v(
                          "\n                未开餐，暂无评价内容\n              "
                        ),
                      ]
                    ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "hrs" }),
            _vm._v(" "),
            _c("div", [
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "bigdata-main-center-main-1-5" }, [
                _c("div", { staticClass: "bigdata-main-center-table" }, [
                  _vm._m(4),
                  _vm._v(" "),
                  _c("div", { staticClass: "bigdata-main-center-table-body" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "1",
                          "line-height": "32px",
                          "margin-bottom": "10px",
                        },
                      },
                      [_vm._v("\n                    摄入（Kcal）")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("img", {
                        staticClass: "table-bg",
                        attrs: { src: require("./images/tb.png"), alt: "" },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "bigdata-main-center-table-body-1" },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.mealsEnergyRate.breakfast.intake)
                            ),
                            _c(
                              "span",
                              {
                                class: {
                                  tup:
                                    _vm.mealsEnergyRate.breakfast.label == "↑"
                                      ? true
                                      : false,
                                  tdown:
                                    _vm.mealsEnergyRate.breakfast.label == "↓"
                                      ? true
                                      : false,
                                  tno:
                                    _vm.mealsEnergyRate.breakfast.label == "√"
                                      ? true
                                      : false,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.mealsEnergyRate.breakfast.label)
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.mealsEnergyRate.lunch.intake)),
                            _c(
                              "span",
                              {
                                class: {
                                  tup:
                                    _vm.mealsEnergyRate.lunch.label == "↑"
                                      ? true
                                      : false,
                                  tdown:
                                    _vm.mealsEnergyRate.lunch.label == "↓"
                                      ? true
                                      : false,
                                  tno:
                                    _vm.mealsEnergyRate.lunch.label == "√"
                                      ? true
                                      : false,
                                },
                              },
                              [_vm._v(_vm._s(_vm.mealsEnergyRate.lunch.label))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.mealsEnergyRate.supper.intake)),
                            _c(
                              "span",
                              {
                                class: {
                                  tup:
                                    _vm.mealsEnergyRate.supper.label == "↑"
                                      ? true
                                      : false,
                                  tdown:
                                    _vm.mealsEnergyRate.supper.label == "↓"
                                      ? true
                                      : false,
                                  tno:
                                    _vm.mealsEnergyRate.supper.label == "√"
                                      ? true
                                      : false,
                                },
                              },
                              [_vm._v(_vm._s(_vm.mealsEnergyRate.supper.label))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.mealsEnergyRate.total.intake)),
                            _c(
                              "span",
                              {
                                class: {
                                  tup:
                                    _vm.mealsEnergyRate.total.label == "↑"
                                      ? true
                                      : false,
                                  tdown:
                                    _vm.mealsEnergyRate.total.label == "↓"
                                      ? true
                                      : false,
                                  tno:
                                    _vm.mealsEnergyRate.total.label == "√"
                                      ? true
                                      : false,
                                },
                              },
                              [_vm._v(_vm._s(_vm.mealsEnergyRate.total.label))]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "bigdata-main-center-table-body" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "1",
                          "line-height": "32px",
                          "margin-bottom": "10px",
                        },
                      },
                      [_vm._v("\n                    推荐量（Kcal）")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("img", {
                        staticClass: "table-bg",
                        attrs: { src: require("./images/tb.png"), alt: "" },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "bigdata-main-center-table-body-1" },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.mealsEnergyRate.breakfast.recommend)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.mealsEnergyRate.lunch.recommend)),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.mealsEnergyRate.supper.recommend)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.mealsEnergyRate.total.recommend)),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "bigdata-main-center-table-body" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "1",
                          "line-height": "32px",
                          "margin-bottom": "10px",
                        },
                      },
                      [_vm._v("\n                    溢出范围）")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("img", {
                        staticClass: "table-bg",
                        attrs: { src: require("./images/tb.png"), alt: "" },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "bigdata-main-center-table-body-1" },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.mealsEnergyRate.breakfast.overflow)
                            ),
                            (
                              _vm.mealsEnergyRate.breakfast.overflow == "---"
                                ? false
                                : true
                            )
                              ? _c("span", [_vm._v("%")])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.mealsEnergyRate.lunch.overflow)),
                            (
                              _vm.mealsEnergyRate.lunch.overflow == "---"
                                ? false
                                : true
                            )
                              ? _c("span", [_vm._v("%")])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.mealsEnergyRate.supper.overflow)),
                            (
                              _vm.mealsEnergyRate.supper.overflow == "---"
                                ? false
                                : true
                            )
                              ? _c("span", [_vm._v("%")])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.mealsEnergyRate.total.overflow)),
                            (
                              _vm.mealsEnergyRate.total.overflow == "---"
                                ? false
                                : true
                            )
                              ? _c("span", [_vm._v("%")])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(5),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bigdata-main-right" }, [
            _vm._m(6),
            _vm._v(" "),
            _c("div", { staticClass: "bigdata-main-right-table" }, [
              _vm._m(7),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bigdata-main-right-table-list",
                  class: {
                    "table-active-3":
                      _vm.allDay.calories.grade == "低下" ? true : false,
                    "table-active-4":
                      _vm.allDay.calories.grade == "超标" ? true : false,
                    "table-active-2":
                      _vm.allDay.calories.grade == "充裕" ? true : false,
                  },
                },
                [
                  _c("div", { staticStyle: { width: "130px" } }, [
                    _vm._v("能量(Kcal)"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.calories.intake)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.calories.standard)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.calories.rate)),
                    (_vm.allDay.calories.rate == "---" ? false : true)
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.calories.grade == "充裕" ||
                    _vm.allDay.calories.grade == "正常"
                      ? _c("i", { staticClass: "checked" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.calories.grade == "低下"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/up.png"), alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.calories.grade == "超标"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/down.png"), alt: "" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-active-1",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v(_vm._s(_vm.allDay.calories.grade))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bigdata-main-right-table-list",
                  class: {
                    "table-active-3":
                      _vm.allDay.carbohydrate.grade == "低下" ? true : false,
                    "table-active-4":
                      _vm.allDay.carbohydrate.grade == "超标" ? true : false,
                    "table-active-2":
                      _vm.allDay.carbohydrate.grade == "充裕" ? true : false,
                  },
                },
                [
                  _c("div", { staticStyle: { width: "130px" } }, [
                    _vm._v("碳水化合物(g)"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.carbohydrate.intake)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.carbohydrate.standard)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.carbohydrate.rate)),
                    (_vm.allDay.carbohydrate.rate == "---" ? false : true)
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.carbohydrate.grade == "充裕" ||
                    _vm.allDay.carbohydrate.grade == "正常"
                      ? _c("i", { staticClass: "checked" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.carbohydrate.grade == "低下"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/up.png"), alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.carbohydrate.grade == "超标"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/down.png"), alt: "" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-active-1",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v(_vm._s(_vm.allDay.carbohydrate.grade))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bigdata-main-right-table-list",
                  class: {
                    "table-active-3":
                      _vm.allDay.protein.grade == "低下" ? true : false,
                    "table-active-4":
                      _vm.allDay.protein.grade == "超标" ? true : false,
                    "table-active-2":
                      _vm.allDay.protein.grade == "充裕" ? true : false,
                  },
                },
                [
                  _c("div", { staticStyle: { width: "130px" } }, [
                    _vm._v("蛋白质(g)"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.protein.intake)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.protein.standard)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.protein.rate)),
                    (_vm.allDay.protein.rate == "---" ? false : true)
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.protein.grade == "充裕" ||
                    _vm.allDay.protein.grade == "正常"
                      ? _c("i", { staticClass: "checked" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.protein.grade == "低下"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/up.png"), alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.protein.grade == "超标"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/down.png"), alt: "" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-active-1",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v(_vm._s(_vm.allDay.protein.grade))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bigdata-main-right-table-list",
                  class: {
                    "table-active-3":
                      _vm.allDay.fat.grade == "低下" ? true : false,
                    "table-active-4":
                      _vm.allDay.fat.grade == "超标" ? true : false,
                    "table-active-2":
                      _vm.allDay.fat.grade == "充裕" ? true : false,
                  },
                },
                [
                  _c("div", { staticStyle: { width: "130px" } }, [
                    _vm._v("脂肪(g)"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.fat.intake)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.fat.standard)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.fat.rate)),
                    (_vm.allDay.fat.rate == "---" ? false : true)
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.fat.grade == "充裕" ||
                    _vm.allDay.fat.grade == "正常"
                      ? _c("i", { staticClass: "checked" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.fat.grade == "低下"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/up.png"), alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.fat.grade == "超标"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/down.png"), alt: "" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-active-1",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v(_vm._s(_vm.allDay.fat.grade))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bigdata-main-right-table-list",
                  class: {
                    "table-active-3":
                      _vm.allDay.vitamin_a.grade == "低下" ? true : false,
                    "table-active-4":
                      _vm.allDay.vitamin_a.grade == "超标" ? true : false,
                    "table-active-2":
                      _vm.allDay.vitamin_a.grade == "充裕" ? true : false,
                  },
                },
                [
                  _c("div", { staticStyle: { width: "130px" } }, [
                    _vm._v("维生素A(μgRAE)"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_a.intake)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_a.standard)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_a.rate)),
                    (_vm.allDay.vitamin_a.rate == "---" ? false : true)
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_a.grade == "充裕" ||
                    _vm.allDay.vitamin_a.grade == "正常"
                      ? _c("i", { staticClass: "checked" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_a.grade == "低下"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/up.png"), alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_a.grade == "超标"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/down.png"), alt: "" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-active-1",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v(_vm._s(_vm.allDay.vitamin_a.grade))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bigdata-main-right-table-list",
                  class: {
                    "table-active-3":
                      _vm.allDay.vitamin_e.grade == "低下" ? true : false,
                    "table-active-4":
                      _vm.allDay.vitamin_e.grade == "超标" ? true : false,
                    "table-active-2":
                      _vm.allDay.calories.grade == "充裕" ? true : false,
                  },
                },
                [
                  _c("div", { staticStyle: { width: "130px" } }, [
                    _vm._v("维生素E(mg)"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_e.intake)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_e.standard)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_e.rate)),
                    (_vm.allDay.vitamin_e.rate == "---" ? false : true)
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_e.grade == "充裕" ||
                    _vm.allDay.vitamin_e.grade == "正常"
                      ? _c("i", { staticClass: "checked" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_e.grade == "低下"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/up.png"), alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_e.grade == "超标"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/down.png"), alt: "" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-active-1",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v(_vm._s(_vm.allDay.vitamin_e.grade))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bigdata-main-right-table-list",
                  class: {
                    "table-active-3":
                      _vm.allDay.vitamin_b1.grade == "低下" ? true : false,
                    "table-active-4":
                      _vm.allDay.vitamin_b1.grade == "超标" ? true : false,
                    "table-active-2":
                      _vm.allDay.vitamin_b1.grade == "充裕" ? true : false,
                  },
                },
                [
                  _c("div", { staticStyle: { width: "130px" } }, [
                    _vm._v("维生素B1(mg)"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_b1.intake)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_b1.standard)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_b1.rate)),
                    (_vm.allDay.vitamin_b1.rate == "---" ? false : true)
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_b1.grade == "充裕" ||
                    _vm.allDay.vitamin_b1.grade == "正常"
                      ? _c("i", { staticClass: "checked" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_b1.grade == "低下"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/up.png"), alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_b1.grade == "超标"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/down.png"), alt: "" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-active-1",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v(_vm._s(_vm.allDay.vitamin_b1.grade))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bigdata-main-right-table-list",
                  class: {
                    "table-active-3":
                      _vm.allDay.vitamin_b2.grade == "低下" ? true : false,
                    "table-active-4":
                      _vm.allDay.vitamin_b2.grade == "超标" ? true : false,
                    "table-active-2":
                      _vm.allDay.vitamin_b2.grade == "充裕" ? true : false,
                  },
                },
                [
                  _c("div", { staticStyle: { width: "130px" } }, [
                    _vm._v("维生素B2(mg)"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_b2.intake)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_b2.standard)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_b2.rate)),
                    (_vm.allDay.vitamin_b2.rate == "---" ? false : true)
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_b2.grade == "充裕" ||
                    _vm.allDay.vitamin_b2.grade == "正常"
                      ? _c("i", { staticClass: "checked" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_b2.grade == "低下"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/up.png"), alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_b2.grade == "超标"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/down.png"), alt: "" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-active-1",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v(_vm._s(_vm.allDay.vitamin_b2.grade))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bigdata-main-right-table-list",
                  class: {
                    "table-active-3":
                      _vm.allDay.vitamin_b3.grade == "低下" ? true : false,
                    "table-active-4":
                      _vm.allDay.vitamin_b3.grade == "超标" ? true : false,
                    "table-active-2":
                      _vm.allDay.vitamin_b3.grade == "充裕" ? true : false,
                  },
                },
                [
                  _c("div", { staticStyle: { width: "130px" } }, [
                    _vm._v("维生素B3(mg)"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_b3.intake)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_b3.standard)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_b3.rate)),
                    (_vm.allDay.vitamin_b3.rate == "---" ? false : true)
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_b3.grade == "充裕" ||
                    _vm.allDay.vitamin_b3.grade == "正常"
                      ? _c("i", { staticClass: "checked" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_b3.grade == "低下"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/up.png"), alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_b3.grade == "超标"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/down.png"), alt: "" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-active-1",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v(_vm._s(_vm.allDay.vitamin_b3.grade))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bigdata-main-right-table-list",
                  class: {
                    "table-active-3":
                      _vm.allDay.vitamin_c.grade == "低下" ? true : false,
                    "table-active-4":
                      _vm.allDay.vitamin_c.grade == "超标" ? true : false,
                    "table-active-2":
                      _vm.allDay.vitamin_c.grade == "充裕" ? true : false,
                  },
                },
                [
                  _c("div", { staticStyle: { width: "130px" } }, [
                    _vm._v("维生素C(mg)"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_c.intake)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_c.standard)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.vitamin_c.rate)),
                    (_vm.allDay.vitamin_c.rate == "---" ? false : true)
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_c.grade == "充裕" ||
                    _vm.allDay.vitamin_c.grade == "正常"
                      ? _c("i", { staticClass: "checked" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_c.grade == "低下"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/up.png"), alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.vitamin_c.grade == "超标"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/down.png"), alt: "" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-active-1",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v(_vm._s(_vm.allDay.vitamin_c.grade))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bigdata-main-right-table-list",
                  class: {
                    "table-active-3":
                      _vm.allDay.calcium.grade == "低下" ? true : false,
                    "table-active-4":
                      _vm.allDay.calcium.grade == "超标" ? true : false,
                    "table-active-2":
                      _vm.allDay.calcium.grade == "充裕" ? true : false,
                  },
                },
                [
                  _c("div", { staticStyle: { width: "130px" } }, [
                    _vm._v("钙(mg)"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.calcium.intake)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.calcium.standard)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.calcium.rate)),
                    (_vm.allDay.calcium.rate == "---" ? false : true)
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.calcium.grade == "充裕" ||
                    _vm.allDay.calcium.grade == "正常"
                      ? _c("i", { staticClass: "checked" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.calcium.grade == "低下"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/up.png"), alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.calcium.grade == "超标"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/down.png"), alt: "" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-active-1",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v(_vm._s(_vm.allDay.calcium.grade))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bigdata-main-right-table-list",
                  class: {
                    "table-active-3":
                      _vm.allDay.iron.grade == "低下" ? true : false,
                    "table-active-4":
                      _vm.allDay.iron.grade == "超标" ? true : false,
                    "table-active-2":
                      _vm.allDay.iron.grade == "充裕" ? true : false,
                  },
                },
                [
                  _c("div", { staticStyle: { width: "130px" } }, [
                    _vm._v("铁(mg)"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.iron.intake)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.iron.standard)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.iron.rate)),
                    (_vm.allDay.iron.rate == "---" ? false : true)
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.iron.grade == "充裕" ||
                    _vm.allDay.iron.grade == "正常"
                      ? _c("i", { staticClass: "checked" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.iron.grade == "低下"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/up.png"), alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.iron.grade == "超标"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/down.png"), alt: "" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-active-1",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v(_vm._s(_vm.allDay.iron.grade))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bigdata-main-right-table-list",
                  class: {
                    "table-active-3":
                      _vm.allDay.zinc.grade == "低下" ? true : false,
                    "table-active-4":
                      _vm.allDay.zinc.grade == "超标" ? true : false,
                    "table-active-2":
                      _vm.allDay.zinc.grade == "充裕" ? true : false,
                  },
                },
                [
                  _c("div", { staticStyle: { width: "130px" } }, [
                    _vm._v("锌(mg)"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.zinc.intake)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.zinc.standard)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v(_vm._s(_vm.allDay.zinc.rate)),
                    (_vm.allDay.zinc.rate == "---" ? false : true)
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.zinc.grade == "充裕" ||
                    _vm.allDay.zinc.grade == "正常"
                      ? _c("i", { staticClass: "checked" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.zinc.grade == "低下"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/up.png"), alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allDay.zinc.grade == "超标"
                      ? _c("img", {
                          staticClass: "ud",
                          attrs: { src: require("./images/down.png"), alt: "" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "table-active-1",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v(_vm._s(_vm.allDay.zinc.grade))]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { "margin-top": "15px" } }, [
              _c(
                "div",
                {
                  staticClass: "bigdata-main-center-top-text",
                  staticStyle: {
                    position: "static",
                    "text-align": "left",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _vm._m(8),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "bigdata-main-center-top-2",
                      staticStyle: { width: "420px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "bigdata-main-center-top-2-text" },
                        [_vm._v("食物种类分析")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticStyle: { display: "inline-flex" } }, [
                        _c("span", [_vm._v("食材种类")]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "margin-left": "10px" } }, [
                          _c(
                            "div",
                            { staticClass: "bigdata-main-center-ahs" },
                            [_vm._v(_vm._s(_vm.ingTypesSum[0]))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "bigdata-main-center-ahs" },
                            [_vm._v(_vm._s(_vm.ingTypesSum[1]))]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "right-text",
                      style: {
                        "margin-top": true ? "15px" : "33px",
                        "margin-bottom": true ? "10px" : "30px",
                      },
                    },
                    [
                      _vm._v(
                        "\n                《中国居民膳食指南》建议每日食材摄入12种，每周25种\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "bigdata-main-right-bottom" }, [
                    _c(
                      "div",
                      {
                        staticClass: "bigdata-main-right-bottom-1",
                        class: {
                          "bigdata-main-right-bottom-active":
                            _vm.ingTypesCounts.ddl > 1 ? true : false,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              flex: "1",
                              "text-align": "center",
                              "padding-bottom": "10px",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "bigdata-main-right-bottom-text",
                                },
                                [_vm._v(_vm._s(_vm.ingTypesCounts.ddl))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "bigdata-main-right-bottom-value",
                                },
                                [_vm._v("种")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [_vm._v("大豆类")]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bigdata-main-right-bottom-1",
                        class: {
                          "bigdata-main-right-bottom-active":
                            _vm.ingTypesCounts.rdl > 2 ? true : false,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              flex: "1",
                              "text-align": "center",
                              "padding-bottom": "10px",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "bigdata-main-right-bottom-text",
                                },
                                [_vm._v(_vm._s(_vm.ingTypesCounts.rdl))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "bigdata-main-right-bottom-value",
                                },
                                [_vm._v("种")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [_vm._v("肉蛋类")]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bigdata-main-right-bottom-1",
                        class: {
                          "bigdata-main-right-bottom-active":
                            _vm.ingTypesCounts.sgl > 3 ? true : false,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              flex: "1",
                              "text-align": "center",
                              "padding-bottom": "10px",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "bigdata-main-right-bottom-text",
                                },
                                [_vm._v(_vm._s(_vm.ingTypesCounts.sgl))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "bigdata-main-right-bottom-value",
                                },
                                [_vm._v("种")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [_vm._v("蔬果类")]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bigdata-main-right-bottom-1",
                        class: {
                          "bigdata-main-right-bottom-active":
                            _vm.ingTypesCounts.gwl > 2 ? true : false,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              flex: "1",
                              "text-align": "center",
                              "padding-bottom": "10px",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "bigdata-main-right-bottom-text",
                                },
                                [_vm._v(_vm._s(_vm.ingTypesCounts.gwl))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "bigdata-main-right-bottom-value",
                                },
                                [_vm._v("种")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [_vm._v("谷薯类")]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "rgb(255, 255, 255)",
                        "font-size": "16px",
                        height: "40px",
                        "line-height": "50px",
                        "text-align": "center",
                      },
                    },
                    [
                      _vm._v(
                        "\n                坚果类食材" +
                          _vm._s(_vm.ingTypesCounts.jgl) +
                          "种，其他类食材" +
                          _vm._s(_vm.ingTypesCounts.qtl) +
                          "种"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("img", {
                    staticStyle: {
                      width: "100%",
                      height: "5px",
                      "margin-top": "15px",
                    },
                    attrs: { src: require("./images/line.png"), alt: "" },
                  }),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bigdata-header" }, [
      _c("div", { staticClass: "bigdata-header-title" }, [
        _vm._v("3C营养食谱分析报告系统"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bigdata-table" }, [
      _c("i", {
        staticClass: "radius_",
        staticStyle: { top: "10px", left: "-3px" },
      }),
      _vm._v(" "),
      _c("i", {
        staticClass: "radius_",
        staticStyle: { bottom: "10px", left: "-3px" },
      }),
      _vm._v(" "),
      _c("i", {
        staticClass: "bg_",
        staticStyle: { left: "-2px", background: "#071159" },
      }),
      _vm._v(" "),
      _c("i", {
        staticClass: "radius_",
        staticStyle: { top: "10px", right: "-3px" },
      }),
      _vm._v(" "),
      _c("i", {
        staticClass: "radius_",
        staticStyle: { bottom: "10px", right: "-3px" },
      }),
      _vm._v(" "),
      _c("i", {
        staticClass: "bg_",
        staticStyle: { right: "-2px", background: "#071159" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bigdata-table-title", staticStyle: { width: "170px" } },
        [_vm._v("\n              菜品名称（用量）\n            ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bigdata-table-title", staticStyle: { width: "135px" } },
        [_vm._v("\n              食材名称\n            ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bigdata-table-title", staticStyle: { width: "150px" } },
        [_vm._v("\n              食材用量\n            ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bigdata-main-center-top" }, [
      _c("img", {
        attrs: { src: require("./images/center-top.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "bigdata-main-center-top-text" }, [
        _c("div", { staticClass: "bigdata-main-center-top-1" }, [
          _c("div", { staticClass: "bigdata-main-center-top-1-1" }),
          _vm._v(" "),
          _c("div", { staticClass: "bigdata-main-center-top-1-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bigdata-main-center-top-2" }, [
          _c("div", { staticClass: "bigdata-main-center-top-2-text" }, [
            _vm._v("当前食谱营养分析"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "bigdata-main-center-top-text",
        staticStyle: {
          position: "static",
          "text-align": "left",
          "padding-left": "100px",
          "margin-bottom": "10px",
        },
      },
      [
        _c("div", { staticClass: "bigdata-main-center-top-1" }, [
          _c("div", { staticClass: "bigdata-main-center-top-1-1" }),
          _vm._v(" "),
          _c("div", { staticClass: "bigdata-main-center-top-1-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bigdata-main-center-top-2" }, [
          _c("div", { staticClass: "bigdata-main-center-top-2-text" }, [
            _vm._v("三餐能量占比分析"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bigdata-main-center-table-header" }, [
      _c("div", { staticStyle: { flex: "1" } }),
      _vm._v(" "),
      _c("div", { staticClass: "bigdata-main-center-table-header-title" }, [
        _c("div", [_vm._v("早餐")]),
        _vm._v(" "),
        _c("div", [_vm._v("午餐")]),
        _vm._v(" "),
        _c("div", [_vm._v("晚餐")]),
        _vm._v(" "),
        _c("div", [_vm._v("总热量")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empolder" }, [
      _c("span", [_vm._v("开发单位:湖北致格意诚科技有限公司")]),
      _vm._v(" "),
      _c("span", { staticStyle: { "margin-left": "40px" } }, [
        _vm._v("联系电话:\n              400-900-6769"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "bigdata-main-center-top-text",
        staticStyle: {
          position: "static",
          "text-align": "left",
          "margin-bottom": "10px",
        },
      },
      [
        _c("div", { staticClass: "bigdata-main-center-top-1" }, [
          _c("div", { staticClass: "bigdata-main-center-top-1-1" }),
          _vm._v(" "),
          _c("div", { staticClass: "bigdata-main-center-top-1-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bigdata-main-center-top-2" }, [
          _c("div", { staticClass: "bigdata-main-center-top-2-text" }, [
            _vm._v("三餐能量占比分析"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bigdata-main-right-table-title" }, [
      _c("div", { staticStyle: { width: "130px" } }, [_vm._v("营养成分")]),
      _vm._v(" "),
      _c("div", { staticStyle: { width: "80px" } }, [_vm._v("实际摄入")]),
      _vm._v(" "),
      _c("div", { staticStyle: { width: "80px" } }, [_vm._v("标准摄入")]),
      _vm._v(" "),
      _c("div", { staticStyle: { width: "80px" } }, [_vm._v("摄入占比")]),
      _vm._v(" "),
      _c("div", { staticStyle: { width: "80px" } }, [_vm._v("评价")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bigdata-main-center-top-1" }, [
      _c("div", { staticClass: "bigdata-main-center-top-1-1" }),
      _vm._v(" "),
      _c("div", { staticClass: "bigdata-main-center-top-1-2" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }