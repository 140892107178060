"use strict";

var _interopRequireDefault = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var echarts = _interopRequireWildcard(require("echarts"));
var _axios = _interopRequireDefault(require("axios"));
var _vueSeamlessScroll = _interopRequireDefault(require("vue-seamless-scroll"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var ResizeObserverIns = null;
var _default = exports.default = {
  components: {
    vueSeamlessScroll: _vueSeamlessScroll.default
  },
  data: function data() {
    return {
      calories: null,
      fat: null,
      protein: null,
      carbohydrate: null,
      board: false,
      dailyMealsData: {},
      type: '',
      debounceTimer: null,
      hours: '',
      checkList: [],
      title: '——',
      loading: true,
      load: true,
      flag: false,
      list: [],
      classOption: {
        step: 0.5,
        limitMoveNum: 10,
        hoverStop: true
      },
      base: {
        member_title: ''
      },
      nutritionalRate: [],
      score: 0,
      today: '',
      week: '',
      time: '',
      scoreGrade: '',
      scale: 1
    };
  },
  computed: {
    computedStyle: function computedStyle() {
      return {
        transform: "scale(".concat(this.scale, ")"),
        'transform-origin': '0 0'
      };
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.screenResize();
    ResizeObserverIns = new ResizeObserver(function () {
      _this.screenResize();
    });
    ResizeObserverIns.observe(this.$refs.fullScreen);
    this.getdate();
    this.getlist();
    setInterval(this.getdate, 1000);
  },
  beforeDestroy: function beforeDestroy() {
    ResizeObserverIns && ResizeObserverIns.disconnect();
  },
  methods: {
    debounce: function debounce(callback, delay) {
      var _this2 = this;
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(function () {
        callback();
        _this2.debounceTimer = null;
      }, delay);
    },
    cg: function cg(val) {
      var index = this.checkList.indexOf(val);
      if (index == -1) {
        this.checkList.push(val);
      } else {
        this.checkList.splice(index, 1);
      }
      // if (this.checkList.length) {
      //   this.analysis()
      // }
    },
    rest: function rest() {
      this.flag = false;
      this.checkList = [];
    },
    analysis: function analysis() {
      var _this3 = this;
      this.load = true;
      this.flag = true;
      if (!this.checkList.length) {
        this.flag = false;
        return false;
      }
      var url = window.location.href;
      var number = this.getURLParameter('number', url);
      var meals_type = this.type;
      var dishes_ids = this.checkList.join(',');
      var data = {
        business_number: number,
        meals_type: meals_type,
        dishes_ids: dishes_ids
      };
      _axios.default.post("".concat(process.env.VUE_APP_BASE_API, "bigscreen/choose/analysis"), data).then(function (res) {
        _this3.dailyMealsData = res.data.data.dailyMealsData;
        _this3.calories = _this3.dailyMealsData.analysis.calories;
        _this3.carbohydrate = _this3.dailyMealsData.analysis.carbohydrate;
        _this3.fat = _this3.dailyMealsData.analysis.fat;
        _this3.protein = _this3.dailyMealsData.analysis.protein;

        // let analysis = []
        // let s = ['热量(kcal)','碳水化合物(g)','蛋白质g']

        _this3.load = false;
      });
    },
    getlist: function getlist() {
      var _this4 = this;
      var url = window.location.href;
      var number = this.getURLParameter('number', url);
      var board = this.getURLParameter('board', url);
      if (board == 'true') {
        this.board = true;
      } else {
        this.board = false;
      }
      this.loading = true;
      var date = this.formattedDate();
      _axios.default.get("".concat(process.env.VUE_APP_BASE_API, "bigscreen/choose/dishesList?business_number=").concat(number, "&date=").concat(date)).then(function (res) {
        _this4.loading = false;
        _this4.base = res.data.data.base;
        _this4.type = 'breakfast';
        if (_this4.hours >= 0 && _this4.hours < 10) {
          _this4.type = 'breakfast';
        } else if (_this4.hours >= 10 && _this4.hours < 15) {
          _this4.type = 'lunch';
        } else {
          _this4.type = 'supper';
        }
        if (_this4.type == 'breakfast') {
          _this4.list = res.data.data.meals_group.breakfast;
          if (res.data.data.meals_group.bit_breakfast && res.data.data.meals_group.bit_breakfast.length) {
            _this4.list = _this4.list.concat(res.data.data.meals_group.bit_breakfast);
          }
        } else if (_this4.type == 'lunch') {
          _this4.list = res.data.data.meals_group.lunch;
          if (res.data.data.meals_group.bit_lunch && res.data.data.meals_group.bit_lunch.length) {
            _this4.list = _this4.list.concat(res.data.data.meals_group.bit_lunch);
          }
        } else {
          _this4.list = res.data.data.meals_group.supper;
          if (res.data.data.meals_group.bit_supper && res.data.data.meals_group.bit_supper.length) {
            _this4.list = _this4.list.concat(res.data.data.meals_group.bit_supper);
          }
        }
        if (_this4.list && _this4.list.length) {
          _this4.list.forEach(function (ele) {
            var ilist = [];
            ele.ing_list.forEach(function (v) {
              var str = "".concat(v.ing_name).concat(v.weight, "g");
              ilist.push(str);
            });
            ele.ilist = ilist.join(',');
          });
        } else {
          _this4.list = [];
        }
      });
    },
    getURLParameter: function getURLParameter(name, urlsearch) {
      return decodeURIComponent((new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(urlsearch || location.search) || [, ""])[1].replace(/\+/g, "%20")) || null;
    },
    formattedDate: function formattedDate() {
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth() + 1;
      var day = today.getDate();
      month = month.toString().padStart(2, '0');
      day = day.toString().padStart(2, '0');
      return "".concat(year, "-").concat(month, "-").concat(day);
    },
    getdate: function getdate() {
      var now = new Date(); // 创建一个Date对象
      var year = now.getFullYear(); // 年
      var month = now.getMonth() + 1; // 月（注意加1）
      var date = now.getDate(); // 日
      var days = ['日', '一', '二', '三', '四', '五', '六'];
      var day = days[now.getDay()]; // 星期几

      var hours = now.getHours();
      var minutes = now.getMinutes();
      var seconds = now.getSeconds();
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      var time = hours + ":" + minutes + ":" + seconds;
      this.hours = hours;
      this.time = time;
      this.today = "".concat(year, "\u5E74").concat(month, "\u6708").concat(date, "\u65E5");
      this.week = "\u661F\u671F".concat(day);
    },
    screenResize: function screenResize() {
      this.scale = this.$refs.fullScreen.offsetWidth / 1920;
    }
  }
};