// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"menuText": "#f1f1f1",
	"menuActiveText": "#1890ff",
	"subMenuActiveText": "#1890ff",
	"menuBg": "#0f1222",
	"logoMenuBg": "#0f1222",
	"menuHover": "#2a62f5",
	"subMenuBg": "#3c3d4c",
	"subMenuHover": "#2a62f5",
	"sideBarWidth": "210px",
	"subMenuHoverColor": "#fff",
	"menuHoverColor": "#fff"
};
module.exports = exports;
