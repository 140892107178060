"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// 这个包装数据 形成新的数据
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  config: function config(state) {
    return state.user.config;
  },
  user: function user(state) {
    return state.user.user;
  },
  garden_id: function garden_id(state) {
    return state.user.garden_id;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  perms: function perms(state) {
    return state.user.perms;
  },
  menus: function menus(state) {
    return state.user.menus;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  addRoutes: function addRoutes(state) {
    return state.permission.addRoutes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  currentLayer: function currentLayer(state) {
    return state.map.currentLayer;
  },
  currentPage: function currentPage(state) {
    return state.map.currentPage;
  },
  field: function field(state) {
    return state.app.field;
  },
  dict: function dict(state) {
    return state.app.dict;
  },
  geojson: function geojson(state) {
    return state.app.geojson;
  }
};
var _default = exports.default = getters;