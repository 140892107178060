"use strict";

var _interopRequireDefault = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var state = {
  sidebar: {
    opened: _jsCookie.default.get('sidebarStatus') ? !!+_jsCookie.default.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  size: _jsCookie.default.get('size') || 'medium',
  field: {},
  dict: []
};

// 这里面的函数才能修改上面state里面的数据  这里面只允许写同步代码
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      _jsCookie.default.set('sidebarStatus', 1);
    } else {
      _jsCookie.default.set('sidebarStatus', 0);
    }
  },
  // withoutAnimation 参数是调用这和参数的时候 传进来的参数
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    _jsCookie.default.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_SIZE: function SET_SIZE(state, size) {
    state.size = size;
    _jsCookie.default.set('size', size);
  },
  SET_FIELD: function SET_FIELD(state, field) {
    state.field = field;
  },
  SET_DICT: function SET_DICT(state, dict) {
    state.dict = dict;
  }
};

//  这里面可以写异步代码
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setSize: function setSize(_ref5, size) {
    var commit = _ref5.commit;
    commit('SET_SIZE', size);
  },
  setField: function setField(_ref6, size) {
    var commit = _ref6.commit;
    commit('SET_FIELD', size);
  },
  setdict: function setdict(_ref7, name) {
    var commit = _ref7.commit;
    return new Promise(function (resolve, reject) {
      // const data = { model_class: name, limit: 1000, page: 1 }
      // queryDictionary(data).then(res => {
      //   const dict = res.data.data
      //   try {
      //     dict.forEach(ele => {
      //       // eslint-disable-next-line no-eval
      //       ele.config_info = eval(ele.config_info.replace(/[\\]/g, ''))
      //       ele.field_name = ele.field_name.replace(/[^\u4e00-\u9fa5|,]+/, '')
      //     })
      //   } catch (err) {
      //     Message({
      //       message: '数据字典录入格式有误,请检查！',
      //       type: 'warning',
      //       duration: 5 * 1000
      //     })
      //   }
      //   commit('SET_DICT', dict)
      //   resolve(dict)
      // }).catch(error => {
      //   reject(error)
      // })
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};